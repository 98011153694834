import React from 'react';
import classNames from 'classnames/bind';
import styles from './ButtonIconRound.module.scss';

const cx = classNames.bind(styles);

export default function ButtonIconRound({ icon, number }) {
    return (
        <button className={cx('wrapper')}>
            <div> {icon}</div>
            {number >= 0 && <span className={cx('number')}>{number}</span>}
        </button>
    );
}

import classNames from 'classnames/bind';
import TextMessage from '../TextMessage';
import styles from './Bubble.module.scss';
import ReplyBlock from '../ReplyBlock';

const cx = classNames.bind(styles);

export default function Bubble({ message, reply }) {
    return (
        <div className={cx('wrapper')}>
            {reply && <ReplyBlock reply={reply} />}
            <TextMessage message={message.text} />
        </div>
    );
}

import React, { useEffect, useRef, useState } from 'react';
import Participant from '../Participant';
import { connect } from 'react-redux';
import classNames from 'classnames/bind';
import styles from './Participants.module.scss';
import SlideParticipants from '../SlideParticipants';
import { setStatusShareScreen } from '~/store/action';
import { createAudioStreamWithLowGain, handleAudioStream } from '~/common/utils';

const cx = classNames.bind(styles);

const Participants = (props) => {
    const videoRef = useRef(null);
    let participantKey = Object.keys(props.participants);
    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.srcObject = props.stream;
            videoRef.current.muted = true;
            const idUserCurrent = Object.keys(props.currentUser)[0];
            const streamUser = createAudioStreamWithLowGain(props.stream);
            handleAudioStream(streamUser, `${idUserCurrent}`);
        }
    }, [props.currentUser, props.stream]);

    useEffect(() => {
        const screenPresenter = participantKey.find((element) => {
            const currentParticipant = props.participants[element];
            return currentParticipant.screen;
        });
        // console.log('screenPresenterscreenPresenter', screenPresenter);
        if (screenPresenter) {
            props.setStatusShareScreen(true);
        } else {
            props.setStatusShareScreen(false);
        }
    }, [props.participants]);

    const currentUser = props.currentUser ? Object.values(props.currentUser)[0] : null;
    // console.log('currentUsercurrentUser', currentUser);

    let gridCol = participantKey.length === 1 ? 1 : participantKey.length <= 4 ? 2 : 4;
    let gridColSize = participantKey.length <= 4 ? 1 : 2;
    let gridRowSize = participantKey.length <= 4 ? participantKey.length : Math.ceil(participantKey.length / 2);

    const screenPresenter = participantKey.find((element) => {
        const currentParticipant = props.participants[element];
        return currentParticipant.screen;
    });

    console.log('screenPresenter', screenPresenter);

    if (screenPresenter) {
        gridCol = 1;
        gridRowSize = 2;
        gridColSize = 1;
    }

    let slideParticipants = [];
    const participants = participantKey.map((element, index) => {
        const currentParticipant =
            screenPresenter == element ? props.participantsShare[element] : props.participants[element];
        const isCurrentUser = currentParticipant.currentUser;
        slideParticipants.push({
            currentParticipant: currentParticipant,
            curentIndex: element,
        });
        if (isCurrentUser) {
            const videoSlideElement = document.getElementById(`participantVideoSlide${element}`);
            if (videoSlideElement) {
                videoSlideElement.srcObject = props.stream;
                videoSlideElement.muted = true;
            }
            return null;
        }

        const pc = currentParticipant?.peerConnection;
        const remoteStream = new MediaStream();
        let curentIndex = element;
        if (pc) {
            pc.ontrack = (event) => {
                event.streams[0].getTracks().forEach((track) => {
                    remoteStream.addTrack(track);
                });
                const videElement = document.getElementById(`participantVideo${element}`);
                if (videElement) videElement.srcObject = remoteStream;

                const videoSlideElement = document.getElementById(`participantVideoSlide${element}`);
                if (videoSlideElement) {
                    videoSlideElement.srcObject = remoteStream;
                    videoSlideElement.muted = true;
                    if (curentIndex && remoteStream) {
                        handleAudioStream(remoteStream, `${curentIndex}`);
                    }
                }
            };
        }

        return (
            <Participant
                key={element}
                currentParticipant={currentParticipant}
                curentIndex={curentIndex}
                share={screenPresenter}
                hideVideo={screenPresenter && screenPresenter !== element}
                showAvatar={!currentParticipant.video && !currentParticipant.screen && currentParticipant.userName}
            />
        );
    });
    if(!participants.length) return null
    return (
        <div
            style={{
                '--grid-size': gridCol,
                '--grid-col-size': gridColSize,
                '--grid-row-size': gridRowSize,
            }}
            className={cx('participants')}
        >
            {participants}
            <Participant
                currentParticipant={currentUser}
                curentIndex={Object.keys(props?.currentUser)[0]}
                hideVideo={screenPresenter && !currentUser.screen}
                videoRef={videoRef}
                showAvatar={currentUser && !currentUser.video && !currentUser.screen}
                currentUser={true}
            />
            <SlideParticipants screenPresenter={screenPresenter} slideParticipants={slideParticipants} />
        </div>
    );
};

const mapDispatchToProps = (dispatch) => {
    return {
        setStatusShareScreen: (status) => dispatch(setStatusShareScreen(status)),
    };
};

const mapStatetoProps = (state) => {
    return {
        currentUser: state.currentUser,
        participants: state.participants,
        stream: state.mainStream,
        activeUser: state.activeUser,
        participantsShare: state.participantsShare,
    };
};

export default connect(mapStatetoProps, mapDispatchToProps)(Participants);

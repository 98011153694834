import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import dbRef from '~/server/firebaseConfig';
import {
    addParticipant,
    removeParticipant,
    setRoomId,
    setUser,
    setUserActive,
    setUserStream,
    updateParticipant,
} from '~/store/action';

import classNames from 'classnames/bind';
import { roomIdRef } from '~/global';
import styles from './Room.module.scss';
import RoomJoined from './RoomJoined/RoomJoined';
import RoomWaiting from './RoomWaiting/RoomWaiting';

const cx = classNames.bind(styles);

function Room(props) {
    const location = useLocation();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const urlparams = new URLSearchParams(window.location.search);
        const roomIdLink = urlparams.get('id');
        //trương hợp copy thẳng link
        if (roomIdLink) {
            roomIdRef.current = roomIdLink;
        } else {
            //trường hợp join bằng id
            if (location?.state?.roomId) {
                window.history.replaceState(null, 'Meet', '?id=' + location?.state?.roomId);
                roomIdRef.current = location?.state?.roomId;
            } else {
                //trường hợp chưa có id / tạo mới
                let newDbRef = dbRef.push();
                window.history.replaceState(null, 'Meet', '?id=' + newDbRef.key);
                roomIdRef.current = newDbRef.key;
            }
        }
    }, []);

    if (!props.account) return <RoomWaiting isHost={location?.state?.isHost} />;
    return <RoomJoined isHost={location?.state?.isHost} />;
    // return null;
}

const mapStatetoProps = (state) => {
    return {
        user: state.currentUser,
        participants: state.participants,
        stream: state.mainStream,
        account: state.account,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRoomId: (id) => dispatch(setRoomId(id)),
        setUserStream: (stream) => dispatch(setUserStream(stream)),
        setUser: (user) => dispatch(setUser(user)),
        addParticipant: (participant) => dispatch(addParticipant(participant)),
        removeParticipant: (participantKey) => dispatch(removeParticipant(participantKey)),
        updateParticipant: (user) => dispatch(updateParticipant(user)),
        setUserActive: (user) => dispatch(setUserActive(user)),
    };
};
export default connect(mapStatetoProps, mapDispatchToProps)(Room);

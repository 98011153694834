import React from 'react';
import classNames from 'classnames/bind';
import styles from './Mic.module.scss';

const cx = classNames.bind(styles);

export default function Mic(props) {
    const { keyActive, muted, from } = props;
    if (muted) {
        return (
            <div style={{ fontSize: from == 'slide' ? 0.4 : 0.5 }} className={cx('recorder-muted', 'mic-muted')}>
                <div
                    style={{ fontSize: from == 'slide' ? 0.4 : 0.5 }}
                    id={keyActive}
                    className={cx('level-muted')}
                ></div>
                <div className={cx('dash')}></div>
            </div>
        );
    }
    return (
        <div style={{ fontSize: from == 'slide' ? 0.4 : 0.5 }} className={cx('recorder', 'mic')}>
            <div id={keyActive} style={{ fontSize: from == 'slide' ? 0.4 : 0.5 }} className={cx('level')}></div>
        </div>
    );
}

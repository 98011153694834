import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import dbRef, { connectedRef } from '~/server/firebaseConfig';
import {
    addParticipant,
    removeParticipant,
    setRoomId,
    setUser,
    setUserActive,
    setUserStream,
    updateParticipant,
    addParticipantShare,
    removeParticipantShare,
} from '~/store/action';

import classNames from 'classnames/bind';
import LoadingOverlay from 'react-loading-overlay-ts';
import Participants from '~/components/component/Participants';
import { defaultPreferencesRef, loadingRef, roomIdRef } from '~/global';
import styles from './RoomJoined.module.scss';
import ModalSupportPermision from '~/components/modal/ModalSupportPermision';

const cx = classNames.bind(styles);

function RoomJoined(props) {
    useEffect(() => {
        loadingRef.current?.showLoading();
        setTimeout(() => {
            loadingRef.current?.hideLoading();
        }, 3000);
    }, []);
    // useEffect(() => {
    //     if (videoRef.current) {
    //         videoRef.current.srcObject = props.stream;
    //         videoRef.current.muted = true;
    //     }
    // }, [props.currentUser, props.stream]);
    useEffect(() => {
        handleJoinRoom(roomIdRef.current);
    }, []);

    const handleJoinRoom = (roomId) => {
        // console.log('handleJoinRoom', roomId);
        let participantRef = null;
        let newDbRef;
        newDbRef = dbRef.child(roomId);
        participantRef = newDbRef.child('participants');
        connectedRef.on('value', (snap) => {
            const defaultPreferences = defaultPreferencesRef.current;
            if (snap.val()) {
                const userRef = participantRef.push();
                userRef.set({
                    userName: props.account,
                    preferences: defaultPreferences,
                });
                props.setUser({
                    [userRef.key]: {
                        userName: props.account,
                        ...defaultPreferences,
                    },
                });
                userRef.onDisconnect().remove();
            }
        });

        participantRef.on('child_added', (snap) => {
            //lắng nghe cập nhật của các user
            const preferenceUpdateEvent = participantRef.child(snap.key).child('preferences');
            preferenceUpdateEvent.on('child_changed', (preferenceSnap) => {
                props.updateParticipant({
                    [snap.key]: {
                        [preferenceSnap.key]: preferenceSnap.val(),
                    },
                });
            });

            //thêm mới khi có user mới vào phòng
            const { userName, preferences } = snap.val();
            if (userName && preferences) {
                console.log('preferencespreferencestest', preferences, userName);
                props.addParticipant({
                    [snap.key]: { id: snap.key, userName, ...preferences },
                });

                props.addParticipantShare({
                    [snap.key]: { id: snap.key, userName, ...preferences },
                });
            }
        });

        //xoá user
        participantRef.on('child_removed', (snap) => {
            props.removeParticipant(snap.key);
            props.removeParticipantShare(snap.key);
        });
    };
    return (
        <div className={cx('wrapper')}>
            <div className={cx('content')}>{props?.user && <Participants />}</div>
            <ModalSupportPermision />
        </div>
    );
}

const mapStatetoProps = (state) => {
    return {
        user: state.currentUser,
        participants: state.participants,
        stream: state.mainStream,
        account: state.account,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setRoomId: (id) => dispatch(setRoomId(id)),
        setUserStream: (stream) => dispatch(setUserStream(stream)),
        setUser: (user) => dispatch(setUser(user)),
        addParticipant: (participant) => dispatch(addParticipant(participant)),
        removeParticipant: (participantKey) => dispatch(removeParticipant(participantKey)),
        addParticipantShare: (participant) => dispatch(addParticipantShare(participant)),
        removeParticipantShare: (participantKey) => dispatch(removeParticipantShare(participantKey)),
        updateParticipant: (user) => dispatch(updateParticipant(user)),
        setUserActive: (user) => dispatch(setUserActive(user)),
    };
};
export default connect(mapStatetoProps, mapDispatchToProps)(RoomJoined);

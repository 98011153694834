import classNames from 'classnames/bind';
import { store } from '~/';
import Bubble from '../Bubble';
import Note from '../Note';
import Time from '../Time';
import styles from './Message.module.scss';

import { useSelector } from 'react-redux';
import { ReactComponent as IconReply } from '~/assets/svg/reply-icon.svg';
import { setRepMessage } from '~/store/action';
import MessageFile from './MessageFile';

const cx = classNames.bind(styles);

export default function Message(props) {
    const { createdAt, message, user, reply, type, file } = JSON.parse(props.message);

    const participants = useSelector((state) => state.joined);

    let lastMessIsMe = false;
    const lastMessValue = props.lastMessage ? JSON.parse(props.lastMessage) : null;

    if (lastMessValue?.user?.userId === user?.userId) lastMessIsMe = true;

    const notMe = user?.userId == Object.keys(store.getState().currentUser)[0] ? false : true;

    const onRely = () => {
        store.dispatch(setRepMessage({ createdAt, message, user, file }));
    };

    if (type === 'action') return;

    if (!lastMessIsMe)
        return (
            <div>
                {!!props.unread && <div className={cx('unread')}>New message</div>}
                <div className={cx('wrapper')} style={{ flexDirection: notMe ? 'row' : 'row-reverse' }}>
                    <div className={cx('avatar')} style={{ position: 'relative' }}>
                        {notMe && (
                            <div
                                style={{
                                    background: participants?.[user.userId]?.avatarColor,
                                    width: '34px',
                                    height: '34px',
                                    fontSize: '20px',
                                }}
                                className="avatar"
                            >
                                {user.userName[0]}
                            </div>
                        )}
                    </div>

                    <div className={cx('content')}>
                        {notMe && (
                            <div className={cx('more-info')}>
                                <Note user={user} />
                                <Time createdAt={createdAt} />
                            </div>
                        )}
                        <div className={cx('bubble')} style={{ float: notMe ? 'left' : 'right', maxWidth: '80%' }}>
                            {!!message && <Bubble message={message} reply={reply} />}
                            {file && <MessageFile file={file} />}

                            <div className={cx('reply')}>
                                <button onClick={onRely} style={{ color: 'white', cursor: 'pointer' }}>
                                    <IconReply />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

    if (lastMessIsMe)
        return (
            <div>
                {!!props.unread && <div className={cx('unread')}>New message</div>}
                <div className={cx('wrapper-is-me')} style={{ flexDirection: notMe ? 'row' : 'row-reverse' }}>
                    <div className={cx('avatar')}></div>
                    <div className={cx('content')}>
                        <div
                            className={`${cx('mt-2')} ${cx('bubble')}`}
                            style={{ float: notMe ? 'left' : 'right', maxWidth: '80%' }}
                        >
                            {!!message && <Bubble message={message} reply={reply} />}
                            {file && <MessageFile file={file} />}
                            <div className={cx('reply')}>
                                <button onClick={onRely} style={{ color: 'white', cursor: 'pointer' }}>
                                    <IconReply />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
}

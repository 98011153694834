import { login } from '~/store/action';
import { store } from '..';

// Global variables to keep track of audio streams and their volume level
const VOLUME_THRESHOLD = 20; // Adjust this threshold to suit your needs
const LOW_GAIN_VALUE = 0.5;
const AUDIO_WINDOW_SIZE = 256;
let audioStreams = new Map();

const checkLogin = () => {
    const { isAuthenticated } = store.getState();
    console.log("isAuthenticated", isAuthenticated);
    if (isAuthenticated) {
        store.dispatch(login(false));
        return true;
    } else {
        store.dispatch(login(true));
        return false;
    }
};

const findInMap = (map, val) => {
    for (let [k, v] of map) {
        // console.log('findInMap', k, val);
        if (k === val) {
            return true;
        }
    }
    return false;
};

// Function to update the active speaker indicator on the page
function updateActiveSpeakerIndicator(speakerId, averageVolume) {
    // console.log(`${speakerId} is ${averageVolume ? `` : `not`} an active speaker`);
    const microDiv = document.getElementById(`micro${speakerId}`);
    const microSlideDiv = document.getElementById(`microSlide${speakerId}`);
    const avatarDiv = document.getElementById(`avatar${speakerId}`);
    var x = window.matchMedia('(max-width: 800px)');
    // console.log('microDivmicroDiv', speakerId, averageVolume);
    // microDiv.style.border = microDiv.classList.toggle('red-border', isActiveSpeaker);
    if (microDiv) {
        microDiv.style.height = (averageVolume > VOLUME_THRESHOLD ? averageVolume - VOLUME_THRESHOLD / 3 : 0) + '%';
    }
    if (microSlideDiv) {
        microSlideDiv.style.height =
            (averageVolume > VOLUME_THRESHOLD ? averageVolume - VOLUME_THRESHOLD / 3 : 0) + '%';
    }
    if (avatarDiv) {
        if (averageVolume > VOLUME_THRESHOLD) {
            // console.log('avatarActive');
            avatarDiv.style.animation = x.matches
                ? 'circle-size-small 0.5s linear infinite alternate'
                : 'circle-size 0.5s linear infinite alternate';
        } else {
            avatarDiv.style.animation = '';
        }
    }
}

// Function to handle incoming audio streams from WebRTC peers
function handleAudioStream(stream, userId) {
    try {
        const checkExist = findInMap(audioStreams, userId);
        if (checkExist) return;
        // console.log('handleAudioStream', userId, stream);
        const audioContext = new AudioContext();
        const mediaStreamSource = audioContext.createMediaStreamSource(stream);

        // Create an analyser node to process audio data
        const analyserNode = audioContext.createAnalyser();
        // Window size in samples that is used when performing a Fast Fourier Transform (FFT),
        // to get frequency domain data
        analyserNode.fftSize = AUDIO_WINDOW_SIZE;
        mediaStreamSource.connect(analyserNode);

        // Buffer to hold the audio data
        const bufferLength = analyserNode.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        // Add the audio stream and its analyser node to the global map
        audioStreams.set(userId, { stream, analyserNode });
        // console.log('asddd');
        // Function to process audio data and detect the active speaker
        function processAudio() {
            analyserNode.getByteFrequencyData(dataArray);

            // Implement your active speaker detection algorithm here
            // For example, you can calculate the average volume of the audio data and use a threshold

            // Example: Calculate the average volume
            const averageVolume = dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
            // console.log('averageVolume', averageVolume);
            updateActiveSpeakerIndicator(userId, averageVolume);

            if (findInMap(audioStreams, userId)) {
                // Repeat the process for the next audio frame
                requestAnimationFrame(processAudio);
            }
        }

        // Start the audio processing loop
        processAudio();
        // console.log('audioStreams', audioStreams);
    } catch (e) {
        console.log('err handleAudio', e);
    }
}

// Function to remove audio stream and stop active speaker detection
function removeAudioStream(userId) {
    const streamData = audioStreams.get(userId);
    if (streamData) {
        streamData.stream.getTracks().forEach((track) => track.stop());
        streamData.analyserNode.disconnect();
        // console.log('removeAudioStream', userId);
        audioStreams.delete(userId);
    }
}

// Function to create stream with 50% gain of the original stream
function createAudioStreamWithLowGain(stream) {
    try {
        const ctx = new AudioContext();
        const gainNode = ctx.createGain();
        const audioDest = ctx.createMediaStreamDestination();
        const source = ctx.createMediaStreamSource(stream);

        gainNode.connect(audioDest);
        gainNode.gain.value = LOW_GAIN_VALUE;
        source.connect(gainNode);
        const lowGainStream = audioDest.stream;
        return lowGainStream;
    } catch (e) {
        console.log('err createAudioStreamWithLowGain', e);
        return null;
    }
}

export { createAudioStreamWithLowGain, handleAudioStream, removeAudioStream, checkLogin };

import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';

const firebaseConfig = {
    apiKey: 'AIzaSyBQn5x7LAe_J9mMqFi9zGGL8r8DkaGBH6U',
    authDomain: 'hoc-vi-vu-room.firebaseapp.com',
    databaseURL: 'https://hoc-vi-vu-room-default-rtdb.firebaseio.com',
    projectId: 'hoc-vi-vu-room',
    storageBucket: 'hoc-vi-vu-room.appspot.com',
    messagingSenderId: '949212783449',
    appId: '1:949212783449:web:945d50f2bf8c56176ea248',
    measurementId: 'G-G3V6CPPPHT',
};
// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
var dbRef = firebase.database().ref();
export const auth = firebase.auth();

export let connectedRef = firebase.database().ref('.info/connected');

export default dbRef;

import Room from '~/pages/Room';
import Home from '~/pages/Home';
import { RoomLayout } from '~/components/Layout';

const publicRoutes = [
    { path: '/', component: Home },
    { path: 'room', component: Room, layout: RoomLayout, isAuth: true },
];
const privateRoutes = [];

export { publicRoutes, privateRoutes };

import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './Home.module.scss';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { setAccount } from '~/store/action';
import dbRef from '~/server/firebaseConfig';
import { checkLogin } from '~/common/utils';
import { callbackLogin, loadingRef } from '~/global';

const cx = classNames.bind(styles);

function Home(props) {
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [data, setData] = useState('');

    useEffect(() => {
        loadingRef.current?.showLoading();
        const fetchData = () => {
            dbRef.on('value', (snapshot) => {
                const dataObject = snapshot.val();
                const dataList = dataObject
                    ? Object.keys(dataObject).map((key) => ({
                          id: key,
                          ...dataObject[key],
                      }))
                    : [];

                loadingRef.current?.hideLoading();
                setData(dataList);
            });
        };

        fetchData();
        return () => dbRef.off();
    }, []);

    const onTextChange = (e) => {
        setValue(e.target.value);
    };

    const goToRoom = () => {
        const room = data.find((item) => item.id === value);
        if (room) {
            navigate('/room', { state: { roomId: value, isHost: false } });
        } else {
            alert('Room không tồn tại');
        }
    };
    const handleJoinRoom = () => {
        let isLogin = checkLogin();
        if (!isLogin) {
            callbackLogin.current = goToRoom;
            return;
        }

        goToRoom();
        // props.setAccount(value);
    };

    const createNewRoom = () => {
        let isLogin = checkLogin();
        if (!isLogin) {
            callbackLogin.current = () => navigate('/room', { state: { isHost: true, roomId: null } });
            return;
        }
        navigate('/room', { state: { isHost: true, roomId: null } });
    };
    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <h1 className={cx('title')}>Tham gia cuộc họp</h1>
                <div className={cx('controls')}>
                    <label>ID cuộc họp hoặc tên liên kết cá nhân</label>
                    <input
                        className={cx('input')}
                        name="input"
                        value={value}
                        onChange={onTextChange}
                        placeholder="Nhập ID cuộc họp hoặc tên liên kết cá nhân."
                    />
                    <div className={cx('rule')}>
                        Bằng cách bấm vào “Tham gia”, bạn đồng ý với <a>Điều khoản dịch vụ</a> và{' '}
                        <a>Tuyên bố về quyền riêng tư</a> của chúng tôi
                    </div>
                </div>
                <button
                    disabled={value.length == 0}
                    onClick={handleJoinRoom}
                    className={value.length > 0 ? cx('button-join-active') : cx('button-join')}
                >
                    Tham gia
                </button>
                <button onClick={createNewRoom} className={cx('button-create')}>
                    Tạo cuộc họp mới
                </button>
            </div>
        </div>
    );
}

const mapStatetoProps = (state) => {
    return {};
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (data) => dispatch(setAccount(data)),
    };
};

export default connect(mapStatetoProps, mapDispatchToProps)(Home);

import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './FooterRoom.module.scss';
import {
    IconMutedVoice,
    IconDisableCamera,
    IconPaticipant,
    IconChat,
    IconShareScreen,
    IconPoll,
    IconRecordButton,
    IconMicList,
    IconCameraList,
    IconRaiseHand,
    IconCancelRaise,
} from '~/components/icons/LoadSvgIcon';
import ButtonIconText from '~/components/component/ButtonIconText';
import { connect, useSelector } from 'react-redux';
import { defaultPreferencesRef, mainStreamDefaultRef } from '~/global';
import {
    addParticipantShare,
    handleModalPermision,
    setShareScreenStream,
    setStatusSideBar,
    setUserShareScreen,
    setUserStream,
    updateUser,
} from '~/store/action';
import dbRef, { connectedRef } from '~/server/firebaseConfig';
import { roomIdRef } from '~/global';
import { store } from '~/index';

const cx = classNames.bind(styles);

function FooterRoom(props) {
    const unread = useSelector((state) => state.unread);
    const userId = store.getState()?.currentUser;

    const raise = (userId && !props?.participants?.[Object.keys(userId)[0]]?.action) || false;

    const participantRef = useRef(props.participants);

    const [streamState, setStreamState] = useState({
        ...defaultPreferencesRef.current,
        mic: defaultPreferencesRef.current?.audio ? true : false,
    });
    useEffect(() => {
        participantRef.current = props.participants;
    }, [props.participants]);

    const onMicClick = () => {
        console.log('onMicClick', mainStreamDefaultRef.current);
        try {
            if (mainStreamDefaultRef.current) {
                mainStreamDefaultRef.current.getAudioTracks()[0].enabled = !streamState.mic;
                setStreamState((currentState) => {
                    return {
                        ...currentState,
                        mic: !currentState.mic,
                    };
                });
                props.updateUser({ audio: !streamState.mic });
            } else {
                props.handleModalPermision('mic');
            }
        } catch (e) {
            console.log('errr mic', e);
            props.handleModalPermision('mic');
        }
    };

    const onVideoClick = (videoEnabled) => {
        try {
            if (props.stream) {
                props.stream.getVideoTracks()[0].enabled = !streamState.video;
                setStreamState((currentState) => {
                    return {
                        ...currentState,
                        video: !currentState.video,
                    };
                });
                props.updateUser({ video: !streamState.video });
            } else {
                props.handleModalPermision('camera');
            }
        } catch (e) {
            console.log('not camera', e);
            props.handleModalPermision('camera');
        }
    };

    const handleLeave = () => {
        window.location.href = '/';
    };

    const onScreenShareEnd = async (key) => {
        let participantRef = null;
        let newDbRef;
        newDbRef = dbRef.child(roomIdRef.current);
        participantRef = newDbRef.child('participants');
        participantRef.child(key).remove();
    };

    const onScreenClick = async () => {
        try {
            let participantKey = Object.keys(props.participants);
            const screenPresenter = participantKey.find((element) => {
                const currentParticipant = props.participants[element];
                return currentParticipant.screen;
            });
            if (screenPresenter) {
                alert('Bạn không thể chia sẻ màn hình, vì có người đang chia sẻ!');
                return;
            }
            let mediaStream;
            if (navigator.getDisplayMedia) {
                mediaStream = await navigator.getDisplayMedia({ video: true, audio: false });
            } else if (navigator.mediaDevices.getDisplayMedia) {
                mediaStream = await navigator.mediaDevices.getDisplayMedia({
                    video: true,
                    audio: false,
                });
            } else {
                mediaStream = await navigator.mediaDevices.getUserMedia({
                    video: { mediaSource: 'screen' },
                    audio: false,
                });
            }

            //them user share screen
            let participantRef = null;
            let newDbRef;
            newDbRef = dbRef.child(roomIdRef.current);
            participantRef = newDbRef.child('participants');
            connectedRef.on('value', (snap) => {
                const defaultPreferences = {
                    audio: false,
                    video: true,
                    screen: true,
                };
                if (snap.val()) {
                    const userRef = participantRef.push();
                    userRef.set({
                        userName: props.account + '(ScreenShare)',
                        preferences: defaultPreferences,
                    });
                    // console.log('addd user to state');
                    props.setUserShareScreen({
                        [userRef.key]: {
                            userName: props.account + '(ScreenShare)',
                            ...defaultPreferences,
                        },
                    });
                    props.setShareScreenStream(mediaStream);
                    setTimeout(() => {
                        //them cac user cu vao list user share
                        let participantKey = Object.keys(props.participants);
                        participantKey.map((element, index) => {
                            // if (element != Object.keys(props.currentUser)[0]) {
                            props.addParticipantShare({
                                [element]: { ...props.participants[element] },
                            });
                            // }
                        });

                        mediaStream.getVideoTracks()[0].onended = () => onScreenShareEnd(userRef.key);
                    }, 1000);

                    userRef.onDisconnect().remove();
                }
            });
        } catch (e) {
            console.log('err onScreenClick', e);
        }
    };

    const showListParticipants = (data) => {
        props.setStatusSideBar(data);
    };

    let notMic = props.listNotHaveMedia.find((el) => el == 'mic');
    let notCamera = props.listNotHaveMedia.find((el) => el == 'camera');

    return (
        <footer className={cx('wrapper')}>
            <div className={cx('footer-left')}>
                <ButtonIconText
                    onClick={onMicClick}
                    notPermission={notMic}
                    icon={
                        streamState?.mic ? (
                            <IconMicList width={25} height={25} />
                        ) : (
                            <IconMutedVoice width={25} height={25} />
                        )
                    }
                    text={streamState?.mic ? 'Turn Off' : 'Turn Audio'}
                />
                <ButtonIconText
                    onClick={onVideoClick}
                    notPermission={notCamera}
                    icon={
                        streamState?.video ? (
                            <IconCameraList width={25} height={25} />
                        ) : (
                            <IconDisableCamera width={25} height={25} />
                        )
                    }
                    text={streamState?.video ? 'Stop Video' : 'Start Video'}
                />
            </div>
            <div className={cx('footer-center')}>
                <ButtonIconText
                    onClick={() => showListParticipants(1)}
                    icon={<IconPaticipant width={35} height={25} />}
                    text={'Participants'}
                />

                <div className={cx('sx_hidden')}>
                    <ButtonIconText
                        onClick={onScreenClick}
                        icon={<IconShareScreen width={40} height={28} />}
                        text={'Share Screen'}
                    />
                </div>

                <div className={cx('chat-control')}>
                    <ButtonIconText
                        onClick={() => showListParticipants(2)}
                        icon={<IconChat width={30} height={25} />}
                        text={'Chat'}
                    />

                    {unread > 0 && <div className={cx('unread')}>{unread}</div>}
                </div>

                <div id="button-raise" className={cx('chat-control')}>
                    <ButtonIconText
                        icon={
                            raise ? (
                                <IconRaiseHand width={30} height={25} />
                            ) : (
                                <IconCancelRaise width={30} height={25} />
                            )
                        }
                        text={raise ? 'Dơ tay' : 'Hạ tay'}
                    />
                </div>

                {/* <ButtonIconText icon={<IconPoll width={25} height={25} />} text={'Poll'} /> */}
                {/* <ButtonIconText icon={<IconRecordButton width={25} height={25} />} text={'Record'} /> */}
            </div>
            <div className={cx('footer-right')}>
                <button onClick={handleLeave}>Leave</button>
            </div>
        </footer>
    );
}

const mapStateToProps = (state) => {
    return {
        stream: state.mainStream,
        participants: state.participants,
        currentUser: state.currentUser,
        account: state.account,
        userShareScreen: state.userShareScreen,
        listNotHaveMedia: state.listNotHaveMedia,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setMainStream: (stream) => dispatch(setUserStream(stream)),
        updateUser: (user) => dispatch(updateUser(user)),
        setStatusSideBar: (data) => dispatch(setStatusSideBar(data)),
        setUserShareScreen: (user) => dispatch(setUserShareScreen(user)),
        addParticipantShare: (participant) => dispatch(addParticipantShare(participant)),
        setShareScreenStream: (stream) => dispatch(setShareScreenStream(stream)),
        handleModalPermision: (status) => dispatch(handleModalPermision(status)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FooterRoom);

import React, { useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './SlideParticipants.module.scss';
import { IconMutedVoice, IconMicList } from '~/components/icons/LoadSvgIcon';
import { connect } from 'react-redux';
import Mic from '../Mic';

const cx = classNames.bind(styles);

function SlideParticipants(props) {
    const videoRef = useRef(null);
    if (props.slideParticipants.length == 0) return null;
    console.log('props.slideParticipants', props.slideParticipants.length);
    const participants = props.slideParticipants?.map((element, index) => {
        if (!element?.currentParticipant || element?.currentParticipant?.screen) return null;
        const { currentParticipant, curentIndex } = element;
        return (
            <div key={curentIndex} className={cx(`item-video`)}>
                <video
                    ref={videoRef}
                    style={{ objectFit: 'cover' }}
                    className={cx('video')}
                    id={`participantVideoSlide${curentIndex}`}
                    autoPlay
                    playsInline
                ></video>

                {currentParticipant?.audio ? (
                    <div className={cx('muted-slide')}>
                        <Mic keyActive={`microSlide${curentIndex}`} from={'slide'} />
                    </div>
                ) : (
                    <div className={cx('muted-slide')}>
                        <Mic keyActive={`microSlide`} muted={true} from={'slide'} />
                    </div>
                )}
                {!currentParticipant?.video && (
                    <div style={{ background: currentParticipant?.avatarColor }} className={cx('avatar')}>
                        {currentParticipant.userName[0]}
                    </div>
                )}
                <div className={cx('name-slide')}>
                    {currentParticipant.userName}
                    {Object.keys(props.currentUser)[0] == curentIndex ? '(You)' : ''}
                    {/* {props.stream ? '' : 'Chỉ xem'} */}
                </div>
            </div>
        );
    });

    if(!participants.length) return null
    return (
        <div className={cx(`${props.screenPresenter && props.statusOptionLayout != 3 ? 'wrapper' : 'hide'}`)}>
            <div className={cx('container')}>{participants}</div>
        </div>
    );
}

const mapStatetoProps = (state) => {
    return {
        currentUser: state.currentUser,
        participants: state.participants,
        stream: state.mainStream,
        activeUser: state.activeUser,
        statusOptionLayout: state.statusOptionLayout,
    };
};

export default connect(mapStatetoProps)(SlideParticipants);

import { IconDownload } from '~/components/icons/LoadSvgIcon';

export default function MessageFile({ file }) {
    if (file.type === 'image') return <img style={{ width: '100%' }} src={file.link} />;

    return (
        <a
            href={file.link}
            target="blank"
            style={{
                background: 'rgb(78 78 78)',
                color: '#fff',
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
                borderRadius: '8px',
                padding: '0 32px 14px 14px',
            }}
        >
            <IconDownload width={20} height={20} />
            {file.name}
        </a>
    );
}

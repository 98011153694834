import axios from 'axios';

export const getDataProfile = (token) => {
    return new Promise(async (resolve, reject) => {
        try {
            let url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${token}`;
            let data = await axios.get(url);
            console.log('datadatadata', data);
            if(data.status == 200){
                resolve(data.data)
            } else {
                resolve(null)
            }
        } catch (e) {
            reject(e)
            console.log('err getDataProfile', e);
        }
    })
}
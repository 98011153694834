import classNames from 'classnames/bind';
import { useSelector } from 'react-redux';
import ChatFeed from '~/components/MessageComponent/ChatFeed';
import InputMessage from '~/components/MessageComponent/InputMessage';
import styles from '../SideBarRoom.module.scss';
import ReplyBlock from '~/components/MessageComponent/ReplyBlock';
import { ReactComponent as IconClose } from '~/assets/svg/close-icon.svg';
import { store } from '~/index';
import { clearRepMessage } from '~/store/action';
const cx = classNames.bind(styles);
export default function MessageList(props) {
    const messages = useSelector((state) => state.messages);
    const replyState = useSelector((state) => state.reply);

    const clearReply = () => {
        store.dispatch(clearRepMessage());
    };

    return (
        <div className={cx('wrapper')}>
            <div className={cx('header')}>
                <div className={cx('title')}>
                    <p>Chat</p>
                </div>
                <button onClick={props.hideSideBar} className={cx('btn-close')}>
                    <IconClose />
                </button>
            </div>

            <div className={cx('body')}>
                {/* {messages.map((item, index) => (
                    <ItemMessage message={item} key={index} />
                ))} */}
                <ChatFeed
                    messages={messages} // Array: list of message objects
                />
            </div>
            <div className={cx('footer', 'message-footer')}>
                <div className={cx('options-bar')}>
                    {/* <div className={cx('address-send')}>Mọi người</div> */}
                    <div></div>
                </div>
                {replyState && (
                    <div className={cx('reply-block')}>
                        <ReplyBlock reply={replyState} />
                        <div className={cx('close-btn')} onClick={clearReply}>
                            <IconClose />
                        </div>
                    </div>
                )}
                <InputMessage />
            </div>
        </div>
    );
}

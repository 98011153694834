import { roomIdRef } from '~/global';
import { addNewMessage, clearMessageFile, raiseHand, setMessageUnread } from '~/store/action';
import { store } from '../index';
import dbRef from './firebaseConfig';
import { USER_ACTION } from '~/common/constant';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

export const updatePreference = (userId, preference) => {
    try {
        let roomDbRef = dbRef.child(roomIdRef.current);
        let participantRef = roomDbRef.child('participants');
        const currentParticipantRef = participantRef.child(userId).child('preferences');
        setTimeout(() => {
            currentParticipantRef.update(preference);
        });
    } catch (e) {
        console.log('err updatePreference', e);
    }
};

export const createOffer = async (peerConnection, receiverId, createdID, stream) => {
    try {
        console.log('createOffer', receiverId, createdID);
        let roomDbRef = dbRef.child(roomIdRef.current);
        let participantRef = roomDbRef.child('participants');
        const currentParticipantRef = participantRef.child(receiverId);
        peerConnection.onicecandidate = (event) => {
            event.candidate &&
                currentParticipantRef.child('offerCandidates').push({ ...event.candidate.toJSON(), userId: createdID });
        };
        let offerDescription = null;
        // if (stream) {
        // offerDescription = await peerConnection.createOffer();
        // } else {
        offerDescription = await peerConnection.createOffer({
            offerToReceiveVideo: true,
            offerToReceiveAudio: true,
        });
        // }

        await peerConnection.setLocalDescription(offerDescription);

        const offer = {
            sdp: offerDescription.sdp,
            type: offerDescription.type,
            userId: createdID,
        };

        await currentParticipantRef.child('offers').push().set({ offer });
    } catch (e) {
        console.log('errr createOffer', e);
    }
};

export const ConnectToChannel = (peerConnection) => {
    try {
        var handleDataChannelOpen = function (event) {};

        var messageInput = document.querySelector('#input-message');
        var sendBtn = document.querySelector('#button-send');
        var raiseBtn = document.querySelector('#button-raise');
        var uploadBtn = document.querySelector('#button-upload');

        const debouncedClickHandler = debounce(function (event) {
            const participants = store.getState()?.participants;
            const userId = Object.keys(store.getState().currentUser)[0];
            let message = JSON.stringify({
                type: 'action',
                action_type: !!participants[userId]?.action ? USER_ACTION.UN_RAISE_HAND : USER_ACTION.RAISE_HAND,
                message: {
                    text: '',
                },
                user: {
                    avatar: '',
                    userName: Object.values(store.getState().currentUser)[0]?.userName,
                    userId: userId,
                },
                createdAt: new Date().getTime(),
            });

            if (dataChannel.readyState == 'open') {
                dataChannel?.send(message);
                store.dispatch(raiseHand(userId));
            }
        }, 300);

        if (raiseBtn?.addEventListener) {
            // lắng nghe sự kiện dơ tay
            raiseBtn.addEventListener('click', debouncedClickHandler);
        }

        if (uploadBtn?.addEventListener) {
            //lắng nghe sư kiện upload
            uploadBtn.addEventListener('click', async function (event) {
                const messFile = store.getState()?.messFile;
                if (dataChannel.readyState == 'open') {
                    await dataChannel?.send(messFile);
                    store.dispatch(clearMessageFile());
                }
            });
        }

        if (sendBtn?.addEventListener) {
            //lắng nghe sự kiện gửi tin nhắn
            sendBtn.addEventListener('click', function (event) {
                let data = messageInput.value;
                if (data.length > 0) {
                    const reply = store.getState()?.reply || '';
                    const userId = Object.keys(store.getState().currentUser)[0];
                    let message = JSON.stringify({
                        type: 'message',
                        message: {
                            text: data,
                        },
                        user: {
                            avatar: '',
                            userName: Object.values(store.getState().currentUser)[0]?.userName,
                            userId: userId,
                        },
                        reply,
                        createdAt: new Date().getTime(),
                    });
                    if (dataChannel.readyState == 'open') {
                        dataChannel?.send(message);
                    }
                }
            });
        }

        //xử lí tin nhắn nhận được
        var handleDataChannelMessageReceived = function (event) {
            const data = JSON.parse(event.data);

            if (data?.type === 'action' && data?.action_type === 1) {
                toast(`${data.user.userName} đang dơ tay`, {
                    position: 'top-center',
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: false,
                    theme: 'dark',
                });
                store.dispatch(raiseHand(data.user.userId));
            }

            if (data?.type === 'message') store.dispatch(setMessageUnread());
            store.dispatch(addNewMessage(event.data));
        };

        var handleDataChannelError = function (error) {
            console.log('dataChannel.OnError:', error);
        };

        var handleDataChannelClose = function (event) {
            console.log('dataChannel.OnClose', event);
        };

        var handleChannelCallback = function (event) {
            dataChannel = event.channel;
            dataChannel.onopen = handleDataChannelOpen;
            dataChannel.onmessage = handleDataChannelMessageReceived;
            dataChannel.onerror = handleDataChannelError;
            dataChannel.onclose = handleDataChannelClose;
        };

        peerConnection.ondatachannel = handleChannelCallback;
        var dataChannel = peerConnection.createDataChannel('dataChannelName', {});

        dataChannel.onopen = handleDataChannelOpen;
        dataChannel.onmessage = handleDataChannelMessageReceived;
        dataChannel.onerror = handleDataChannelError;
        dataChannel.onclose = handleDataChannelClose;
    } catch (e) {
        console.log('err ConnectToChannel', e);
    }
};

export const initializeListensers = async (userId) => {
    try {
        let roomDbRef = dbRef.child(roomIdRef.current);
        let participantRef = roomDbRef.child('participants');
        const currentUserRef = participantRef.child(userId);

        currentUserRef.child('offers').on('child_added', async (snapshot) => {
            const data = snapshot.val();
            console.log('offersoffersoffersoffers', data);
            if (data?.offer) {
                const pc = store.getState()?.participants[data?.offer?.userId]?.peerConnection;
                if (pc) {
                    await pc.setRemoteDescription(new RTCSessionDescription(data.offer));
                }
                setTimeout(async () => {
                    await createAnswer(data.offer.userId, userId);
                }, 1000);
            }
        });

        currentUserRef.child('offerCandidates').on('child_added', (snapshot) => {
            const data = snapshot.val();
            if (data.userId) {
                const pc = store.getState()?.participants[data?.userId]?.peerConnection;
                if (pc) {
                    pc.addIceCandidate(new RTCIceCandidate(data));
                }
            }
        });

        currentUserRef.child('answers').on('child_added', (snapshot) => {
            const data = snapshot.val();
            if (data?.answer) {
                const pc = store.getState()?.participants[data?.answer?.userId]?.peerConnection;
                const answerDescription = new RTCSessionDescription(data.answer);

                if (pc) {
                    pc.setRemoteDescription(answerDescription);
                }
            }
        });

        currentUserRef.child('answerCandidates').on('child_added', (snapshot) => {
            const data = snapshot.val();
            if (data.userId) {
                const pc = store.getState()?.participants[data?.userId]?.peerConnection;
                if (pc) {
                    pc.addIceCandidate(new RTCIceCandidate(data));
                }
            }
        });
    } catch (e) {
        console.log('err initializeListensers', e);
    }
};

const createAnswer = async (otherUserId, userId) => {
    try {
        console.log('createAnswer', otherUserId, userId);
        let roomDbRef = dbRef.child(roomIdRef.current);
        let participantRef = roomDbRef.child('participants');
        const pc = store.getState()?.participants[otherUserId]?.peerConnection;
        const participantRef1 = participantRef.child(otherUserId);
        pc.onicecandidate = (event) => {
            event.candidate &&
                participantRef1.child('answerCandidates').push({ ...event.candidate.toJSON(), userId: userId });
        };

        const answerDescription = await pc.createAnswer();
        await pc.setLocalDescription(answerDescription);

        const answer = {
            type: answerDescription.type,
            sdp: answerDescription.sdp,
            userId: userId,
        };

        await participantRef1.child('answers').push().set({ answer });
    } catch (e) {
        console.log('err createAnswer', e);
    }
};

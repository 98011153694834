import React from 'react';
import classNames from 'classnames/bind';
import styles from './SideBarRoom.module.scss';

import { connect, useSelector } from 'react-redux';
import { clearMessageUnread, setStatusSideBar } from '~/store/action';
import ListParticipants from './sidebarcomponents/ListParticipants';
import MessageList from './sidebarcomponents/MessageList';
import { store } from '~/index';

const cx = classNames.bind(styles);
function Sidebar(props) {
    const hideSideBar = () => {
        props.setStatusSideBar(0);
    };

    const unread = useSelector((state) => state.unread);

    const readNewMess = () => {
        if (unread > 0) store.dispatch(clearMessageUnread());
    };

    // if (props?.statusShowSideBar == 1) {
    //     return <ListParticipants hideSideBar={hideSideBar} />;
    // } else if (props?.statusShowSideBar == 2) {
    //     return <MessageList hideSideBar={hideSideBar} />;
    // } else {
    //     return null;
    // }
    // console.log('(props?.statusShowSideBar', props?.statusShowSideBar);
    return (
        <div onClick={readNewMess}>
            <div className={cx(props?.statusShowSideBar == 1 ? '' : 'hide')}>
                <ListParticipants hideSideBar={hideSideBar} />
            </div>
            <div className={cx(props?.statusShowSideBar == 2 ? '' : 'hide')}>
                <MessageList hideSideBar={hideSideBar} />
            </div>
        </div>
    );
}
const mapStatetoProps = (state) => {
    return {
        participants: state.participants,
        statusShowSideBar: state.statusShowSideBar,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setStatusSideBar: (data) => dispatch(setStatusSideBar(data)),
    };
};

export default connect(mapStatetoProps, mapDispatchToProps)(Sidebar);

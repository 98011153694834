import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import axiosServices from '~/utils/axios';

export const createUpload = async (name) => {
    const { data } = await axiosServices.get(`/upload?name=${name}`);
    return data;
};

export const createLinkView = async (name) => {
    const { data } = await axiosServices.get(`/view?name=${name}`);
    return data;
};

export const uploadFile = async (url, file) => {
    const res = await axios.put(url, file);
    return res;
};

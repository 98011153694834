import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDataProfile } from '~/services/UserService';
import { initialize, login } from '~/store/action';

function AuthProvider({ children }) {
    const dispatch = useDispatch();
    const { isAuthenticated, userProfile } = useSelector((state) => state);
    useEffect(() => {
        (async () => {
            const accessToken = localStorage.getItem('ACCESS_TOKEN');
            if (!accessToken) {
                return dispatch(initialize(false, null));
            }
            try {
                let user = await getDataProfile(accessToken);
                if (user) {
                    return dispatch(initialize(true, user));
                } else {
                    return dispatch(initialize(false, null));
                }
            } catch (e) {
                return dispatch(initialize(false, null));
            }
        })();
    }, []);

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(login(false));
        } else {
            // dispatch(login(true));
        }
    }, [isAuthenticated]);
    console.log('isAuthenticatedisAuthenticated', isAuthenticated);
    return <div>{children}</div>;
}

export default AuthProvider;

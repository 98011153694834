const { createRef } = require('react');

const roomIdRef = createRef();
roomIdRef.current = 'test';

const loadingRef = createRef();

const defaultPreferences = {
    audio: true,
    video: false,
    screen: false,
};

const mainStreamDefaultRef = createRef();
mainStreamDefaultRef.current = null;

const defaultPreferencesRef = createRef();
defaultPreferencesRef.current = defaultPreferences;

const callbackLogin = createRef();
callbackLogin.current = null;

export { roomIdRef, loadingRef, defaultPreferencesRef, mainStreamDefaultRef, callbackLogin };

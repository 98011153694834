import { ConnectToChannel, createOffer, initializeListensers, updatePreference } from '~/server/peerConnection';
import {
    ADD_MESSAGE,
    ADD_PARTICIPANT,
    CLEAR_REP_MESSAGE,
    REMOVE_PARTICIPANT,
    SET_ACCOUNT,
    SET_MAIN_STREAM,
    SET_REP_MESSAGE,
    SET_ROOM_ID,
    SET_STATUS_SIDEBAR,
    SET_USER,
    UPDATE_PARTICIPANT,
    SET_STATUS_SHARE_SCREEN,
    SET_STATUS_LAYOUT,
    SET_SHARE_STREAM,
    SET_USER_SHARE,
    ADD_PARTICIPANT_SHARE,
    REMOVE_PARTICIPANT_SHARE,
    UPDATE_USER,
    SET_UNREAD,
    CLEAR_UNREAD,
    INITIALIZE,
    LOGIN,
    RAISE_HAND,
    SET_MESS_FILE,
    CLEAR_MESS_FILE,
    MODAL_PERMISION,
    SET_LIST_NOT_HAVE_PERMISSION,
} from './actiontypes';
import { loadingRef } from '~/global';
import { removeAudioStream } from '~/common/utils';
import { createOfferShare, initializeListensersShare } from '~/server/shareScreenConnection';
import { USER_ACTION } from '~/common/constant';

let initState = {
    currentUser: null,
    participants: {},
    participantsShare: {},
    mainStream: null,
    roomId: null,
    statusShowSideBar: 0,
    account: null,
    statusShareScreen: false,
    statusOptionLayout: 1,
    messages: [],
    userShareScreen: null,
    shareStream: null,
    isAuthenticated: null,
    userProfile: null,
    isShowModalLogin: false,
    showModalPermision: null,
    listNotHaveMedia: [],
};

const servers = {
    iceServers: [
        { urls: ['stun:v-stream.api4hub.com:5349'] },

        {
            username: 'test',
            credential: 'test1234',
            urls: ['turn:v-stream.api4hub.com:3478'],
        },
        {
            username: 'test',
            credential: 'test1234',
            urls: ['turn:v-stream.api4hub.com:3478?transport=udp'],
        },
    ],
};

const serversB = {
    offerToReceiveVideo: true,
    offerToReceiveAudio: true,
    //iceTransportPolicy: 'relay',
    //sdpSemantics: 'unified-plan',
    sdpSemantics: 'plan-b',
    bundlePolicy: 'max-compat',
    rtcpMuxPolicy: 'negotiate',
    iceServers: [
        { urls: ['stun:v-stream.api4hub.com:5349'] },

        {
            username: 'test',
            credential: 'test1234',
            urls: ['turn:v-stream.api4hub.com:3478'],
        },
        {
            username: 'test',
            credential: 'test1234',
            urls: ['turn:v-stream.api4hub.com:3478?transport=udp'],
        },
    ],
};

const generateColor = () => '#' + Math.floor(Math.random() * 16777215).toString(16);

export const reducer = (state = initState, action) => {
    switch (action.type) {
        case SET_LIST_NOT_HAVE_PERMISSION: {
            let { payload } = action;
            state = { ...state, listNotHaveMedia: payload.list };
            return state;
        }
        case LOGIN: {
            let { payload } = action;
            state = { ...state, isShowModalLogin: payload.status };
            return state;
        }
        case MODAL_PERMISION: {
            let { payload } = action;
            state = { ...state, showModalPermision: payload.status };
            return state;
        }
        case INITIALIZE: {
            let { payload } = action;
            state = { ...state, isAuthenticated: payload.isAuthenticated, userProfile: payload.user };
            return state;
        }
        case SET_SHARE_STREAM: {
            let { payload } = action;
            state = { ...state, shareStream: payload.stream };
            return state;
        }
        case SET_USER_SHARE: {
            let { payload } = action;
            const userId = Object.keys(payload.user)[0];
            initializeListensersShare(userId);
            console.log('SET_USER_SHARE', payload.user);
            state = { ...state, userShareScreen: payload.user };
            return state;
        }
        case ADD_MESSAGE: {
            let { payload } = action;
            const newMessages = [...state.messages, payload.message];
            state = { ...state, messages: newMessages };
            return state;
        }

        case SET_MESS_FILE: {
            let { payload } = action;
            state = { ...state, messFile: payload.messFile };
            return state;
        }

        case CLEAR_MESS_FILE: {
            state = { ...state, messFile: null };
            return state;
        }

        case RAISE_HAND: {
            let { payload } = action;
            let participants = { ...state.participants };

            if (participants[payload.userId]) {
                participants[payload.userId].action = !!participants[payload.userId].action
                    ? USER_ACTION.UN_RAISE_HAND
                    : USER_ACTION.RAISE_HAND;
            }

            state = { ...state, participants };
            return state;
        }

        case SET_UNREAD: {
            state = { ...state, unread: state?.unread + 1 || 1 };
            return state;
        }

        case CLEAR_UNREAD: {
            state = { ...state, unread: 0 };
            return state;
        }

        case SET_REP_MESSAGE: {
            let { payload } = action;
            state = { ...state, reply: payload.reply };
            return state;
        }

        case CLEAR_REP_MESSAGE: {
            state = { ...state, reply: '' };
            return state;
        }

        case SET_STATUS_LAYOUT: {
            let { payload } = action;
            state = { ...state, statusOptionLayout: payload.status };
            return state;
        }
        case SET_STATUS_SHARE_SCREEN: {
            let { payload } = action;
            state = {
                ...state,
                statusShareScreen: payload.status,
                statusOptionLayout: !payload.status ? 1 : state.statusOptionLayout,
            };
            return state;
        }
        case SET_ACCOUNT: {
            let { payload } = action;
            state = { ...state, ...payload };
            return state;
        }
        case SET_STATUS_SIDEBAR: {
            let { payload } = action;
            state = { ...state, ...payload };
            return state;
        }
        case UPDATE_USER: {
            let payload = action.payload;
            const userId = Object.keys(state.currentUser)[0];
            updatePreference(userId, payload.currentUser);
            state.currentUser[userId] = {
                ...state.currentUser[userId],
                ...payload.currentUser,
            };
            // console.log('UPDATE_USER', state.currentUser);
            state = {
                ...state,
                currentUser: { ...state.currentUser },
            };
            return state;
        }
        case SET_ROOM_ID: {
            let { payload } = action;
            state = { ...state, ...payload };
            return state;
        }
        case SET_MAIN_STREAM: {
            let { payload } = action;
            state = { ...state, ...payload };
            return state;
        }

        case SET_USER: {
            let payload = action.payload;
            let participants = { ...state.participants };
            const userId = Object.keys(payload.currentUser)[0];
            payload.currentUser[userId].avatarColor = generateColor();
            initializeListensers(userId);
            initializeListensersShare(userId);
            state = { ...state, currentUser: { ...payload.currentUser }, participants };
            return state;
        }

        case ADD_PARTICIPANT: {
            let payload = action.payload;
            if (!state.currentUser || !payload.newUser) return state;
            const currentUserId = Object.keys(state.currentUser)[0];
            const newUserId = Object.keys(payload.newUser)[0];
            if (currentUserId !== newUserId) {
                payload.newUser = addConnection(payload.newUser, state.currentUser, state.mainStream);
            }

            if (currentUserId === newUserId) payload.newUser[newUserId].currentUser = true;
            payload.newUser[newUserId].avatarColor = generateColor();
            let participants = { ...state.participants, ...payload.newUser };
            state = { ...state, participants, joined: participants };
            return state;
        }

        case REMOVE_PARTICIPANT: {
            let payload = action.payload;
            let participants = { ...state.participants };
            delete participants[payload.id];
            removeAudioStream(`micro${payload.id}`);
            state = { ...state, participants };
            return state;
        }

        case ADD_PARTICIPANT_SHARE: {
            let payload = action.payload;
            if ((!state.userShareScreen && !state.currentUser) || !payload.newUser) return state;
            const currentUser = state?.userShareScreen ? state.userShareScreen : state.currentUser;
            // console.log('ADD_PARTICIPANT_SHARE', state.userShareScreen);
            const currentUserId = Object.keys(currentUser)[0];
            const currentUserValue = Object.values(currentUser)[0];
            const newUserId = Object.keys(payload.newUser)[0];
            const newUserValue = Object.values(payload.newUser)[0];
            if (currentUserId !== newUserId && (currentUserValue.screen || newUserValue.screen)) {
                console.log(
                    'addConnectionShare',
                    payload.newUser,
                    currentUser,
                    state.shareStream ? state.shareStream : state.mainStream,
                );
                payload.newUser = addConnectionShare(
                    payload.newUser,
                    currentUser,
                    state.shareStream ? state.shareStream : state.mainStream,
                );
            }
            if (currentUserId === newUserId) payload.newUser[newUserId].currentUser = true;
            let participantsShare = { ...state.participantsShare, ...payload.newUser };
            state = { ...state, participantsShare };
            return state;
        }

        case REMOVE_PARTICIPANT_SHARE: {
            let payload = action.payload;
            let participantsShare = { ...state.participantsShare };
            delete participantsShare[payload.id];
            state = { ...state, participantsShare };
            return state;
        }

        case UPDATE_PARTICIPANT: {
            let payload = action.payload;
            const newUserId = Object.keys(payload.newUser)[0];

            payload.newUser[newUserId] = {
                ...state.participants[newUserId],
                ...payload.newUser[newUserId],
            };
            let participants = { ...state.participants, ...payload.newUser };
            state = { ...state, participants };
            return state;
        }
        default: {
            return state;
        }
    }
};

const addConnection = (newUser, currentUser, stream) => {
    try {
        const peerConnection = new RTCPeerConnection(servers);
        ConnectToChannel(peerConnection);
        if (stream) {
            stream.getTracks().forEach((track) => {
                peerConnection.addTrack(track, stream);
            });
        }

        const newUserId = Object.keys(newUser)[0];
        // console.log('Object.keys(newUser)[0]', Object.values(newUser)[0]);
        const currentUserId = Object.keys(currentUser)[0];

        const offerIds = [newUserId, currentUserId].sort((a, b) => a.localeCompare(b));

        newUser[newUserId].peerConnection = peerConnection;
        //thang vao sau thi tao offer
        if (offerIds[0] !== currentUserId) {
            createOffer(peerConnection, offerIds[0], offerIds[1], stream);
        }
        return newUser;
    } catch (e) {
        console.log('err e', e);
        return null;
    }
};

const addConnectionShare = (newUser, currentUser, stream) => {
    // console.log('addConnectionShare', newUser, currentUser, stream);
    try {
        const peerConnection = new RTCPeerConnection(servers);
        if (stream) {
            stream.getTracks().forEach((track) => {
                peerConnection.addTrack(track, stream);
            });
        }

        const newUserId = Object.keys(newUser)[0];
        const currentUserId = Object.keys(currentUser)[0];

        const offerIds = [newUserId, currentUserId].sort((a, b) => a.localeCompare(b));
        newUser[newUserId].peerConnection = peerConnection;
        console.log('currentUserIdcurrentUserId', currentUserId, offerIds);
        if (offerIds[0] !== currentUserId) {
            createOfferShare(peerConnection, offerIds[0], offerIds[1], stream);
        }

        return newUser;
    } catch (e) {
        console.log('err addConnectionShare', e);
        return null;
    }
};

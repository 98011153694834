import React from 'react';
import classNames from 'classnames/bind';
import styles from './Header.module.scss';
import { HocvivuLogo, IconArrowDown, IconSearch, IconCart, IconLogout } from '~/components/icons/LoadSvgIcon';
import ButtonIconRound from '~/components/component/ButtonIconRound';
import { useGoogleLogin } from '@react-oauth/google';
import { getDataProfile } from '~/services/UserService';
import { useDispatch, useSelector } from 'react-redux';
import { initialize, login } from '~/store/action';
import { googleLogout } from '@react-oauth/google';

const cx = classNames.bind(styles);

export default function Header() {
    const dispatch = useDispatch();
    const { userProfile } = useSelector((state) => state);
    console.log('userProfileuserProfile', userProfile);
    const signIn = () => {
        dispatch(login(true));
    };

    const logOut = () => {
        googleLogout();
        localStorage.removeItem('ACCESS_TOKEN');
        dispatch(initialize(false, null));
    };
    return (
        <div className={cx('wrapper')}>
            <div className={cx('header-left')}>
                <div className={cx('header-logo')}>
                    <HocvivuLogo className={cx('logo')} />
                </div>
                <div className={cx('list-menu')}>
                    <ul>
                        <li>
                            <a>Home</a>
                        </li>
                        <li>
                            <a>About</a>
                        </li>
                        <li>
                            <a>Courses</a>
                            <IconArrowDown width={16} height={16} />
                        </li>
                        <li>
                            <a>Pages</a>
                            <IconArrowDown width={16} height={16} />
                        </li>
                    </ul>
                </div>
            </div>
            <div className={cx('header-right')}>
                <ButtonIconRound icon={<IconSearch width={18} height={18} />} />
                <ButtonIconRound icon={<IconCart width={20} height={18} />} number={0} />
                {userProfile ? (
                    <div className={cx('info-user')}>
                        {' '}
                        <p>{`Hi, ${userProfile?.given_name}`}</p>
                        <button onClick={logOut}>
                            <IconLogout width={20} height={20} />
                        </button>
                    </div>
                ) : (
                    <button onClick={signIn} className={cx('sign-in')}>
                        Sign in
                    </button>
                )}
            </div>
        </div>
    );
}

import React from 'react';
import styles from './ModalSupportPermision.module.scss';
import classNames from 'classnames/bind';
import { IconClose, IconInfoPage, IconNotePermision } from '~/components/icons/LoadSvgIcon';
import Modal from 'react-modal';
import img_note from '~/assets/images/noti_permision.png';
import { useDispatch, useSelector } from 'react-redux';
import { handleModalPermision } from '~/store/action';

const cx = classNames.bind(styles);

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#fff',
        borderRadius: '20px',
        border: 'none',
    },
};

function ModalSupportPermision(props) {
    const dispatch = useDispatch();
    const { showModalPermision } = useSelector((state) => state);

    const closeModal = () => {
        dispatch(handleModalPermision(null));
    };

    return (
        <div>
            <Modal
                isOpen={showModalPermision}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                // className={cx('modal-login')}
                overlayClassName={cx('overlay')}
            >
                <div className={cx('wrapper')}>
                    <button className={cx('button')} onClick={closeModal}>
                        <IconClose fill="black" width={10} height={10} />
                    </button>
                    <div className={cx('container')}>
                        <div className={cx('image_support')}>
                            <img src={img_note} />
                        </div>
                        <div className={cx('noti_support')}>
                            <h1>{`Hocvivu bị chặn sử dụng ${showModalPermision} của bạn`}</h1>
                            <span>
                                1. Nhấp vào biểu tượng thông tin trang <IconInfoPage height={30} width={30} /> trong
                                thanh địa chỉ của trình duyệt.{' '}
                            </span>
                            <br />
                            <span>{`2. Bật ${showModalPermision}`}</span>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default ModalSupportPermision;

import React from 'react';
import Card from './Card';
import './Participant.css';
import { IconMutedVoice, IconMicList } from '~/components/icons/LoadSvgIcon';
import Mic from '../Mic';

const Participant = (props) => {
    const { curentIndex, currentParticipant, hideVideo, videoRef, showAvatar, currentUser, share } = props;
    if (!currentParticipant) return <></>;
    return (
        <div className={`participant ${hideVideo ? 'hide' : ''}`}>
            <Card>
                <video
                    ref={videoRef}
                    style={{ objectFit: currentParticipant?.screen ? 'contain' : 'cover' }}
                    className="video"
                    id={`participantVideo${curentIndex}`}
                    autoPlay
                    playsInline
                ></video>
                {share ? null : currentParticipant.audio ? (
                    <div className="muted">
                        <Mic keyActive={`micro${curentIndex}`} />
                    </div>
                ) : (
                    <div className="muted">
                        <Mic keyActive={`micro`} muted={true} />
                    </div>
                )}
                {showAvatar && (
                    <div
                        style={{ background: currentParticipant?.avatarColor }}
                        id={`avatar${curentIndex}`}
                        className="avatar"
                    >
                        {currentParticipant.userName[0]}
                    </div>
                )}
                <div className="name">
                    {currentParticipant.userName}
                    {currentUser ? '(You)' : ''}
                </div>
            </Card>
        </div>
    );
};

export default Participant;

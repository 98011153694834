import React from 'react';
import classNames from 'classnames/bind';
import styles from './Time.module.scss';
import moment from 'moment';

const cx = classNames.bind(styles);

export default function Time({ createdAt }) {
    return <div className={cx('time-text')}>{moment(createdAt).format('HH:mm')}</div>;
}

import {
    ADD_MESSAGE,
    ADD_PARTICIPANT,
    CLEAR_REP_MESSAGE,
    REMOVE_PARTICIPANT,
    SET_ACCOUNT,
    SET_MAIN_STREAM,
    SET_REP_MESSAGE,
    SET_ROOM_ID,
    SET_STATUS_LAYOUT,
    SET_STATUS_SIDEBAR,
    SET_USER,
    SET_USER_ACTIVE,
    SET_STATUS_SHARE_SCREEN,
    SET_SHARE_STREAM,
    SET_USER_SHARE,
    ADD_PARTICIPANT_SHARE,
    REMOVE_PARTICIPANT_SHARE,
    UPDATE_PARTICIPANT,
    UPDATE_USER,
    CLEAR_UNREAD,
    SET_UNREAD,
    INITIALIZE,
    LOGIN,
    RAISE_HAND,
    CLEAR_MESS_FILE,
    SET_MESS_FILE,
    MODAL_PERMISION,
    SET_LIST_NOT_HAVE_PERMISSION,
} from './actiontypes';

export const setListNotHavePermission = (list) => {
    return {
        type: SET_LIST_NOT_HAVE_PERMISSION,
        payload: {
            list: list,
        },
    };
};

export const handleModalPermision = (status) => {
    return {
        type: MODAL_PERMISION,
        payload: {
            status: status,
        },
    };
};

export const login = (status) => {
    return {
        type: LOGIN,
        payload: {
            status: status,
        },
    };
};

export const initialize = (isAuthenticated, user) => {
    return {
        type: INITIALIZE,
        payload: {
            isAuthenticated: isAuthenticated,
            user: user,
        },
    };
};

export const setShareScreenStream = (stream) => {
    return {
        type: SET_SHARE_STREAM,
        payload: {
            stream: stream,
        },
    };
};

export const setRepMessage = (message) => {
    return {
        type: SET_REP_MESSAGE,
        payload: {
            reply: message,
        },
    };
};

export const setUserShareScreen = (user) => {
    return {
        type: SET_USER_SHARE,
        payload: {
            user: user,
        },
    };
};
export const clearRepMessage = () => {
    return {
        type: CLEAR_REP_MESSAGE,
    };
};

export const clearMessageUnread = () => {
    return {
        type: CLEAR_UNREAD,
    };
};

export const setMessageUnread = () => {
    return {
        type: SET_UNREAD,
    };
};

export const setMessageFile = (messFile) => {
    return {
        type: SET_MESS_FILE,
        payload: {
            messFile: messFile,
        },
    };
};

export const clearMessageFile = () => {
    return {
        type: CLEAR_MESS_FILE,
    };
};

export const addNewMessage = (message) => {
    return {
        type: ADD_MESSAGE,
        payload: {
            message: message,
        },
    };
};

export const raiseHand = (userId) => {
    return {
        type: RAISE_HAND,
        payload: {
            userId,
        },
    };
};

export const setStatusLayout = (status) => {
    return {
        type: SET_STATUS_LAYOUT,
        payload: {
            status: status,
        },
    };
};

export const setStatusShareScreen = (status) => {
    return {
        type: SET_STATUS_SHARE_SCREEN,
        payload: {
            status: status,
        },
    };
};

export const setUserActive = (user) => {
    return {
        type: SET_USER_ACTIVE,
        payload: {
            activeUser: user,
        },
    };
};

export const updateParticipant = (user) => {
    return {
        type: UPDATE_PARTICIPANT,
        payload: {
            newUser: user,
        },
    };
};

export const setAccount = (data) => {
    return {
        type: SET_ACCOUNT,
        payload: {
            account: data,
        },
    };
};

export const setStatusSideBar = (data) => {
    return {
        type: SET_STATUS_SIDEBAR,
        payload: {
            statusShowSideBar: data,
        },
    };
};

export const updateUser = (user) => {
    return {
        type: UPDATE_USER,
        payload: {
            currentUser: user,
        },
    };
};

export const setRoomId = (id) => {
    return {
        type: SET_ROOM_ID,
        payload: {
            roomId: id,
        },
    };
};

export const setUserStream = (stream) => {
    return {
        type: SET_MAIN_STREAM,
        payload: {
            mainStream: stream,
        },
    };
};

export const setUser = (user) => {
    return {
        type: SET_USER,
        payload: {
            currentUser: user,
        },
    };
};

export const addParticipant = (newUser) => {
    return {
        type: ADD_PARTICIPANT,
        payload: {
            newUser,
        },
    };
};

export const removeParticipant = (id) => {
    return {
        type: REMOVE_PARTICIPANT,
        payload: {
            id,
        },
    };
};

export const addParticipantShare = (newUser) => {
    return {
        type: ADD_PARTICIPANT_SHARE,
        payload: {
            newUser,
        },
    };
};

export const removeParticipantShare = (id) => {
    return {
        type: REMOVE_PARTICIPANT_SHARE,
        payload: {
            id,
        },
    };
};

import React from 'react';
import classNames from 'classnames/bind';
import styles from './HeaderRoom.module.scss';
import {
    Protect,
    OptionHeaderFirst,
    OptionHeaderSecond,
    OptionHeaderThird,
    OptionHeaderFourth,
    OptionHeaderSecondActive,
    OptionHeaderFourthActive,
    OptionHeaderFirstNotActive,
} from '~/components/icons/LoadSvgIcon';
import TimeRecord from '~/components/component/TimeRecord';
import { useDispatch, useSelector } from 'react-redux';
import { setStatusLayout } from '~/store/action';

const cx = classNames.bind(styles);

function HeaderRoom(props) {
    const statusShareScreen = useSelector((state) => state.statusShareScreen);
    const statusOptionLayout = useSelector((state) => state.statusOptionLayout);
    // console.log('statusShareScreen', statusShareScreen);
    const dispatch = useDispatch();

    const changeLayout = (status) => {
        dispatch(setStatusLayout(status));
    };
    return (
        <div className={cx('wrapper')}>
            <div className={cx('left-header')}>
                <Protect width={24} height={24} />
            </div>
            <div className={cx('center-header')}>
                <button className={cx('center-button')} onClick={() => changeLayout(1)}>
                    {statusOptionLayout == 1 ? (
                        <OptionHeaderFirst width={20} height={20} />
                    ) : (
                        <OptionHeaderFirstNotActive width={20} height={20} />
                    )}
                </button>
                {/* {statusShareScreen && (
                    <button className={cx('center-button')} onClick={() => changeLayout(2)}>
                        {statusOptionLayout == 2 ? (
                            <OptionHeaderSecondActive width={20} height={20} />
                        ) : (
                            <OptionHeaderSecond width={20} height={20} />
                        )}
                    </button>
                )} */}
                {/* <button className={cx('center-button')}>
                    <OptionHeaderThird width={24} height={24} />
                </button> */}
                {statusShareScreen && (
                    <button className={cx('center-button')} onClick={() => changeLayout(3)}>
                        {statusOptionLayout == 3 ? (
                            <OptionHeaderFourthActive width={20} height={20} />
                        ) : (
                            <OptionHeaderFourth width={20} height={20} />
                        )}
                    </button>
                )}
            </div>
            <div className={cx('right-header')}>{/* <TimeRecord /> */}</div>
        </div>
    );
}

export default HeaderRoom;

import React from 'react';
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { HocvivuLogo, IconClose, IconGoogle } from '../icons/LoadSvgIcon';
import classNames from 'classnames/bind';
import styles from './LoginModal.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '~/index';
import { initialize, login } from '~/store/action';
import { useGoogleLogin } from '@react-oauth/google';
import { getDataProfile } from '~/services/UserService';
import { callbackLogin, loadingRef } from '~/global';
import axios from 'axios';

const cx = classNames.bind(styles);

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#efefef',
        borderRadius: '20px',
        border: 'none',
    },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)

function LoginModal() {
    const dispatch = useDispatch();
    const signIn = useGoogleLogin({
        onSuccess: (tokenResponse) => {
            loadingRef.current?.showLoading();
            console.log(tokenResponse);
            localStorage.setItem('ACCESS_TOKEN', tokenResponse.access_token);
            let url = `https://www.googleapis.com/oauth2/v1/userinfo?alt=json&access_token=${tokenResponse.access_token}`;
            axios
                .get(url)
                .then((data) => {
                    if (data.status == 200) {
                        const user = data.data;
                        console.log('useruseruser', user, callbackLogin.current);
                        dispatch(initialize(true, user));
                        if (callbackLogin.current) {
                            let callback = callbackLogin.current;
                            closeModal();
                            callback();
                            callbackLogin.current = null;
                        } else {
                            closeModal();
                        }
                        loadingRef.current?.hideLoading();
                    }
                })
                .catch((e) => {
                    loadingRef.current?.hideLoading();
                    console.log('err login', e);
                });
        },
        onError: () => {
            console.log('Login Failed');
            alert('Đăng nhập không thành công!');
        },
    });
    let subtitle;
    const { isShowModalLogin } = useSelector((state) => state);

    function closeModal() {
        store.dispatch(login(false));
        callbackLogin.current = null;
    }

    return (
        <div>
            {/* <button onClick={openModal}>Open Modal</button> */}
            <Modal
                isOpen={isShowModalLogin}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
                // className={cx('modal-login')}
            >
                <div className={cx('wrapper')}>
                    <h1 ref={(_subtitle) => (subtitle = _subtitle)} className={cx('title')}>
                        Login
                    </h1>
                    <button className={cx('button')} onClick={closeModal}>
                        <IconClose fill="black" width={10} height={10} />
                    </button>
                    <div className={cx('body-modal')}>
                        <HocvivuLogo width={250} height={100} />
                        <button onClick={signIn}>
                            <img className={cx('img-google')} src="https://hocvivu.com/img/google_icon.2578ca82.svg" />
                        </button>
                        <span> Can't login? Contact us </span>
                        <div className={cx('list-social')}>
                            <img src="https://hocvivu.com/img/telegram-svgrepo-com.521c7ca3.svg" />
                            <img src="https://hocvivu.com/img/email-8-svgrepo-com.f9ed7f9e.svg" />
                            <img src="https://hocvivu.com/img/facebook-svgrepo-com.91783339.svg" />
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

export default LoginModal;

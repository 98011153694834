import React from 'react';
import classNames from 'classnames/bind';

import styles from './TimeRecord.module.scss';
import { IconRecord } from '~/components/icons/LoadSvgIcon';
const cx = classNames.bind(styles);

export default function TimeRecord() {
    return (
        <div className={cx('wrapper')}>
            <IconRecord className={cx('icon')} width={24} height={24} />
            <span className={cx('text-time')}>13:03:34</span>
        </div>
    );
}

import React from 'react';
import classNames from 'classnames/bind';
import styles from './ItemUser.module.scss';
import {
    IconCameraList,
    IconMutedList,
    IconMicList,
    IconCameraOffList,
    IconRaiseHand,
} from '~/components/icons/LoadSvgIcon';

const cx = classNames.bind(styles);

export default function ItemUser({ user }) {
    const { audio, video, screen, userName, avatarColor, isHost, isCoHost, action } = user;

    // console.log('ItemUserItemUser', user);
    return (
        <div className={cx('wrapper')}>
            <div className={cx('left')}>
                <div className={cx('avatar')} style={{ backgroundColor: avatarColor }}>
                    {userName ? userName?.slice(0, 1) : 'user'}
                </div>
                <div className={cx('info')}>
                    <h4>{userName}</h4>
                    <p>User</p>
                </div>
            </div>
            <div className={cx('right')}>
                {/* {!isHost && <div className={cx('host-item')}>Host</div>} */}
                {isCoHost && <div className={cx('host-item')}>Co-Host</div>}
                {!!action && (
                    <div className={cx('host-item')}>
                        <IconRaiseHand width={20} height={20} />
                    </div>
                )}

                {audio ? (
                    <IconMicList className={cx('item')} width={20} height={20} />
                ) : (
                    <IconMutedList className={cx('item')} width={20} height={20} />
                )}
                {video ? (
                    <IconCameraList className={cx('item')} width={20} height={20} />
                ) : (
                    <IconCameraOffList className={cx('item')} width={20} height={20} />
                )}
            </div>
        </div>
    );
}

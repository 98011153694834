export const SET_MAIN_STREAM = 'SET_MAIN_STREAM';
export const ADD_PARTICIPANT = 'ADD_PARTICIPANT';
export const REMOVE_PARTICIPANT = 'REMOVE_PARTICIPANT';
export const SET_USER = 'SET_USER';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATE_PARTICIPANT = 'UPDATE_PARTICIPANT';
export const SET_ROOM_ID = 'SET_ROOM_ID';
export const SET_STATUS_SIDEBAR = 'SET_STATUS_SIDEBAR';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const SET_USER_ACTIVE = 'SET_USER_ACTIVE';
export const SET_STATUS_SHARE_SCREEN = 'SET_STATUS_SHARE_SCREEN';
export const SET_STATUS_LAYOUT = 'SET_STATUS_LAYOUT';
export const ADD_MESSAGE = 'ADD_MESSAGE';

export const SET_SHARE_STREAM = 'SET_SHARE_STREAM';
export const SET_USER_SHARE = 'SET_USER_SHARE';
export const ADD_PARTICIPANT_SHARE = 'ADD_PARTICIPANT_SHARE';
export const REMOVE_PARTICIPANT_SHARE = 'REMOVE_PARTICIPANT_SHARE';
export const REP_MESSAGE = 'REP_MESSAGE';
export const SET_REP_MESSAGE = 'SET_REP_MESSAGE';
export const CLEAR_REP_MESSAGE = 'CLEAR_REP_MESSAGE';

export const SET_UNREAD = 'SET_UNREAD';
export const CLEAR_UNREAD = 'CLEAR_UNREAD';

export const INITIALIZE = 'INITIALIZE';
export const LOGIN = 'LOGIN';
export const RAISE_HAND = 'RAISE_HAND';
export const SET_MESS_FILE = 'SET_MESS_FILE';
export const CLEAR_MESS_FILE = 'CLEAR_MESS_FILE';

export const MODAL_PERMISION = 'MODAL_PERMISION';

export const SET_LIST_NOT_HAVE_PERMISSION = 'SET_LIST_NOT_HAVE_PERMISSION';

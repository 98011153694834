import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import App from '~/App';
import GlobalStyles from '~/components/GlobalStyles';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import { reducer } from './store/reducer';
import { GoogleOAuthProvider } from '@react-oauth/google';
import AuthProvider from './providers/AuthProvider';
import LoginModal from './components/modal/LoginModal';
import LoadingModal from './components/modal/LoadingModal';
import { loadingRef } from './global';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ModalSupportPermision from './components/modal/ModalSupportPermision';

const queryClient = new QueryClient();
const root = ReactDOM.createRoot(document.getElementById('root'));

export const store = createStore(reducer);
root.render(
    <QueryClientProvider client={queryClient}>
        <Provider store={store}>
            <GlobalStyles>
                <GoogleOAuthProvider clientId="687204469074-lsabni5pigshitna3quetlth4m5k8qqt.apps.googleusercontent.com">
                    <AuthProvider>
                        <LoadingModal ref={loadingRef}>
                            <App />
                            <ToastContainer />
                        </LoadingModal>
                        <LoginModal />
                    </AuthProvider>
                </GoogleOAuthProvider>
            </GlobalStyles>
        </Provider>
    </QueryClientProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

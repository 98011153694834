import dbRef from './firebaseConfig';
import { store } from '../index';
import { roomIdRef } from '~/global';

export const updatePreference = (userId, preference) => {
    try {
        let roomDbRef = dbRef.child(roomIdRef.current);
        let participantRef = roomDbRef.child('participants');
        const currentParticipantRef = participantRef.child(userId).child('preferences');
        setTimeout(() => {
            currentParticipantRef.update(preference);
        });
    } catch (e) {
        console.log('err updatePreference', e);
    }
};

export const createOfferShare = async (peerConnection, receiverId, createdID, stream) => {
    try {
        console.log('createOfferShare', receiverId, createdID, peerConnection);
        let roomDbRef = dbRef.child(roomIdRef.current);
        let participantRef = roomDbRef.child('participants');
        const currentParticipantRef = participantRef.child(receiverId);
        peerConnection.onicecandidate = (event) => {
            event.candidate &&
                currentParticipantRef
                    .child('offerCandidatesShare')
                    .push({ ...event.candidate.toJSON(), userId: createdID });
        };

        let offerDescription = null;
        // if (stream) {
        //     offerDescription = await peerConnection.createOffer();
        // } else {
        offerDescription = await peerConnection.createOffer({
            offerToReceiveVideo: true,
            offerToReceiveAudio: false,
        });
        // }
        await peerConnection.setLocalDescription(offerDescription);

        const offer = {
            sdp: offerDescription.sdp,
            type: offerDescription.type,
            userId: createdID,
        };

        await currentParticipantRef.child('offersShare').push().set({ offer });
    } catch (e) {
        console.log('err createOfferShare', e);
    }
};

export const initializeListensersShare = async (userId) => {
    try {
        let roomDbRef = dbRef.child(roomIdRef.current);
        let participantRef = roomDbRef.child('participants');
        const currentUserRef = participantRef.child(userId);

        currentUserRef.child('offersShare').on('child_added', async (snapshot) => {
            const data = snapshot.val();
            console.log('offersoffersoffersoffersShare', data);
            if (data?.offer) {
                const pc = store.getState()?.participantsShare[data?.offer?.userId]?.peerConnection;
                if (pc) {
                    await pc.setRemoteDescription(new RTCSessionDescription(data.offer));
                }
                await createAnswerShare(data.offer.userId, userId);
            }
        });

        currentUserRef.child('offerCandidatesShare').on('child_added', (snapshot) => {
            const data = snapshot.val();
            if (data.userId) {
                const pc = store.getState()?.participantsShare[data?.userId]?.peerConnection;
                if (pc) {
                    pc.addIceCandidate(new RTCIceCandidate(data));
                }
            }
        });

        currentUserRef.child('answersShare').on('child_added', (snapshot) => {
            const data = snapshot.val();
            if (data?.answer) {
                const pc = store.getState()?.participantsShare[data?.answer?.userId]?.peerConnection;
                const answerDescription = new RTCSessionDescription(data.answer);

                if (pc) {
                    pc.setRemoteDescription(answerDescription);
                }
            }
        });

        currentUserRef.child('answerCandidatesShare').on('child_added', (snapshot) => {
            const data = snapshot.val();
            if (data.userId) {
                const pc = store.getState()?.participantsShare[data?.userId]?.peerConnection;
                if (pc) {
                    pc.addIceCandidate(new RTCIceCandidate(data));
                }
            }
        });
    } catch (e) {
        console.log('err initializeListensersShare', e);
    }
};

const createAnswerShare = async (otherUserId, userId) => {
    try {
        console.log('createAnswerShare', otherUserId, userId, store.getState().participantsShare[otherUserId]);
        let roomDbRef = dbRef.child(roomIdRef.current);
        let participantRef = roomDbRef.child('participants');
        const pc = store.getState()?.participantsShare[otherUserId]?.peerConnection;
        if (!pc) return;
        const participantRef1 = participantRef.child(otherUserId);
        pc.onicecandidate = (event) => {
            event.candidate &&
                participantRef1.child('answerCandidatesShare').push({ ...event.candidate.toJSON(), userId: userId });
        };

        const answerDescription = await pc.createAnswer({
            answerToReceiveVideo: true,
            answerToReceiveAudio: true,
        });
        await pc.setLocalDescription(answerDescription);

        const answer = {
            type: answerDescription.type,
            sdp: answerDescription.sdp,
            userId: userId,
        };

        await participantRef1.child('answersShare').push().set({ answer });
    } catch (e) {
        console.log('err createAnswerShare', e);
    }
};

import React, { useEffect, useRef } from 'react';
import Message from '../Message';
import { useSelector } from 'react-redux';

export default function ChatFeed({ messages }) {
    const unread = useSelector((state) => state.unread);

    const pointUnread = messages.length - unread;

    const messagesEndRef = useRef(null);
    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behaviour: 'smooth' });
    }, [messages]);

    return (
        <div>
            {messages.map((item, index) => {
                return (
                    <Message
                        key={index}
                        unread={pointUnread === index}
                        lastMessage={messages[index - 1]}
                        message={item}
                    />
                );
            })}
            <div ref={messagesEndRef} />
        </div>
    );
}

import React from 'react';
import { useSelector } from 'react-redux';

function AuthGuard({ children }) {
    const { isAuthenticated, userProfile } = useSelector((state) => state);

    return <div>{children}</div>;
}

export default AuthGuard;

import React from 'react';
import Header from '../components/Header';
import HeaderRoom from '../components/HeaderRoom';
import classNames from 'classnames/bind';
import styles from './RoomLayout.module.scss';
import FooterRoom from '../components/FooterRoom';
import Sidebar from '../components/SideBarRoom';
import { useSelector } from 'react-redux';

const cx = classNames.bind(styles);

export default function RoomLayout({ children }) {
    const { statusShowSideBar, account } = useSelector((state) => state);
    // console.log('isShowSideBar', statusShowSideBar);
    return (
        <div className={cx('layout')}>
            {/* <Header /> */}
            <div className={cx('wrapper')}>
                <div className={cx('container')}>
                    {account && (
                        <div>
                            <HeaderRoom />
                        </div>
                    )}

                    <div className={cx('content')}>{children}</div>
                    {account && (
                        <div>
                            <FooterRoom />
                        </div>
                    )}
                </div>
                {/* {statusShowSideBar != 0 && ( */}
                <div className={cx('sidebar', statusShowSideBar == 0 ? 'hide' : '')}>
                    <Sidebar />
                </div>
                {/* )} */}
            </div>
        </div>
    );
}

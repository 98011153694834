import React, { useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './InputMessage.module.scss';
import { ButtonSend, IconAttach } from '~/components/icons/LoadSvgIcon';
import { store } from '~/';
import { addNewMessage, clearMessageUnread, clearRepMessage, setMessageFile } from '~/store/action';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { createLinkView, createUpload, uploadFile } from '~/service';

const cx = classNames.bind(styles);

export default function InputMessage() {
    const [inputValue, setInputValue] = useState('');
    const inputRef = useRef(null);

    const uploadBtn = useRef();

    const reply = useSelector((state) => state.reply);
    const unread = useSelector((state) => state.unread);

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (!file) return;

        const oldName = file.name;
        const fileExtension = file.name.split('.').pop();
        const newFileName = uuidv4() + '.' + fileExtension;
        const renamedFile = new File([file], newFileName, { type: file.type });

        try {
            const { link: createLink } = await createUpload(newFileName);
            await uploadFile(createLink, renamedFile);
            const { link: viewLink } = await createLinkView(newFileName);

            const userId = Object.keys(store.getState().currentUser)[0];

            const file = {
                name: oldName,
                ext: fileExtension,
                type: ['png', 'jpg', 'jpeg'].includes(fileExtension) ? 'image' : 'file',
                link: viewLink,
            };

            let message = JSON.stringify({
                type: 'message',
                message: {
                    text: '',
                },
                file,
                user: {
                    avatar: '',
                    userName: Object.values(store.getState().currentUser)[0]?.userName,
                    userId: userId,
                },
                createdAt: new Date().getTime(),
            });

            store.dispatch(addNewMessage(message));
            store.dispatch(setMessageFile(message));

            uploadBtn.current.click();
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const onValueChange = (e) => {
        if (unread > 0) store.dispatch(clearMessageUnread());
        setInputValue(e.target.value);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') inputRef.current?.click();
    };

    const onSend = () => {
        if (!inputValue.trim()) return;
        const userId = Object.keys(store.getState().currentUser)[0];
        let message = JSON.stringify({
            type: 'message',
            message: {
                text: inputValue,
            },
            user: {
                avatar: '',
                userName: Object.values(store.getState().currentUser)[0]?.userName,
                userId: userId,
            },
            reply,
            createdAt: new Date().getTime(),
        });
        store.dispatch(addNewMessage(message));
        store.dispatch(clearRepMessage());
        setInputValue('');
    };

    return (
        <div className={cx('form-input')}>
            <div>
                <div ref={uploadBtn} id="button-upload"></div>
                <label for="file-upload" className={cx('custom-file-upload')}>
                    <IconAttach width="30" height="30" />
                </label>
                <input id="file-upload" style={{ display: 'none' }} onChange={handleFileChange} type="file" />
            </div>

            <input
                id="input-message"
                value={inputValue}
                className={cx('input-message')}
                onChange={(e) => onValueChange(e)}
                onKeyPress={handleKeyPress}
                autoComplete="off"
                placeholder="Nhắn tin tới mọi người"
            />
            {/* onClick={onRaiseHand} */}

            <button id="button-send" ref={inputRef} onClick={onSend} className={cx('button-send')}>
                <ButtonSend width={40} height={40} style={{ marginRight: '7px' }} />
            </button>
        </div>
    );
}

import React from 'react';
import classNames from 'classnames/bind';
import styles from './Note.module.scss';
import { store } from '~/';

const cx = classNames.bind(styles);

export default function Note({ user, isReply = false }) {
    const { userName, userId } = user;
    let from = '';
    if (userId == Object.keys(store.getState().currentUser)[0]) {
        from = 'bạn';
    } else {
        from = userName;
    }
    if (!isReply)
        return (
            <div className={cx('wrapper')}>
                <p className={cx('text')}>
                    từ <span className={cx('text-special')}>{from}</span> to{' '}
                    <span className={cx('text-special')}>mọi người</span>
                </p>
            </div>
        );

    return (
        <div className={cx('wrapper')}>
            <p className={cx('text')}>
                <span className={cx('text-special')}>{from}</span>
            </p>
        </div>
    );
}

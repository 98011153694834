import React from 'react';
import classNames from 'classnames/bind';
import styles from './ButtonIconText.module.scss';

const cx = classNames.bind(styles);

function ButtonIconText({ icon, text, onClick, notPermission }) {
    return (
        <button className={cx('wrapper')} onClick={onClick}>
            {notPermission ? (
                <div className={cx('container')}>
                    <div className={cx('icon')}>{icon}</div>
                    <div className={cx('warning')}>!</div>
                </div>
            ) : (
                <div className={cx('icon')}>{icon}</div>
            )}
            <div className={cx('text')}>{text}</div>
        </button>
    );
}

export default ButtonIconText;

import React from 'react';
import Note from '../Note';

import { ReactComponent as IconReply } from '~/assets/svg/reply-mess-icon.svg';
import MessageFile from '../Message/MessageFile';
import { IconFile } from '~/components/icons/LoadSvgIcon';

export default function ReplyBlock({ reply }) {
    console.log({ reply });
    return (
        <div
            style={{
                background: 'rgb(100 100 100)',
                color: '#fff',
                padding: `2px 20px`,
                display: 'flex',
                gap: '4px',
                width: '100%',
                borderRadius: '12px',
            }}
        >
            <div
                style={{
                    position: 'relative',
                    top: '4px',
                    left: '-4px',
                }}
            >
                <IconReply />
            </div>
            <div>
                {!reply?.file && <Note user={reply?.user} isReply />}
                {reply?.file && (
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        {reply.file.type === 'image' && (
                            <img style={{ height: '40px', objectFit: 'contain' }} src={reply.file.link} />
                        )}
                        <div>
                            <Note user={reply?.user} isReply />
                            {reply.file.type === 'image' ? (
                                <>Photo Message</>
                            ) : (
                                <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                                    <IconFile width="30" height="30" /> {reply.file.name}
                                </div>
                            )}
                        </div>
                    </div>
                )}
                {reply?.message?.text}
            </div>
        </div>
    );
}

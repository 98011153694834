import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './RoomWaiting.module.scss';
import { useNavigate } from 'react-router-dom';
import {
    login,
    setAccount,
    setUser,
    setUserStream,
    handleModalPermision,
    updateUser,
    setListNotHavePermission,
} from '~/store/action';
import { connect } from 'react-redux';

import {
    IconMutedVoice,
    IconDisableCamera,
    IconPaticipant,
    IconChat,
    IconShareScreen,
    IconPoll,
    IconRecordButton,
    IconMicList,
    IconCameraList,
} from '~/components/icons/LoadSvgIcon';
import ButtonIconText from '~/components/component/ButtonIconText';
import { defaultPreferencesRef, loadingRef, mainStreamDefaultRef } from '~/global';
import { checkLogin, handleAudioStream } from '~/common/utils';
import { store } from '~/index';
import ModalSupportPermision from '~/components/modal/ModalSupportPermision';

const cx = classNames.bind(styles);

function RoomWaiting(props) {
    const [streamState, setStreamState] = useState({
        mic: true,
        video: false,
        screen: false,
    });
    const videoRef = useRef(null);

    useEffect(() => {
        if (videoRef.current) {
            // console.log('videoRefvideoRefvideoRef', props.stream);
            videoRef.current.srcObject = props.stream;
            videoRef.current.muted = true;
        }
    }, [props.stream]);

    // const checkMediaAccess = async () => {
    //     try {
    //         const devices = await navigator.mediaDevices.enumerateDevices();
    //         let mic = false,
    //             camera = false;
    //         devices.forEach((device) => {
    //             if (device.kind == 'audioinput' && device.label) mic = true;
    //             if (device.kind == 'videoinput' && device.label) camera = true;
    //         });
    //         return { mic, camera };
    //     } catch (e) {
    //         console.log('errr checkMediaAccess', e);
    //         return { mic: false, camera: false };
    //     }
    // };

    const handleMediaStream = (mediaStream, mic, camera) => {
        if (mediaStream) {
            if (camera) {
                mediaStream.getVideoTracks()[0].enabled = false;
            }
            if (!mic) {
                setStreamState((currentState) => {
                    defaultPreferencesRef.current = {
                        ...currentState,
                        audio: false,
                    };
                    return {
                        ...currentState,
                        mic: false,
                    };
                });
            }

            props.setUserStream(mediaStream);
            // setIsHaveMedia(true);
            mainStreamDefaultRef.current = mediaStream;
            loadingRef.current?.hideLoading();
        } else {
            // setListNotHavePermission([]);
            console.log('errr');
            // setIsHaveMedia(false);
            setStreamState((currentState) => {
                defaultPreferencesRef.current = {
                    ...currentState,
                    audio: false,
                };
                return {
                    ...currentState,
                    mic: false,
                };
            });
            loadingRef.current?.hideLoading();
        }
    };

    const newRequestMediaWithDevices = async () => {
        try {
            let mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true });
            props.setListNotHavePermission([]);
            handleMediaStream(mediaStream, true, true);
        } catch (e) {
            console.log('request mic va camera loi');
            try {
                let mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: false });
                props.setListNotHavePermission(['camera']);
                handleMediaStream(mediaStream, true, false);
            } catch (e) {
                console.log('request mic loi');
                setStreamState((currentState) => {
                    defaultPreferencesRef.current = {
                        ...currentState,
                        audio: false,
                    };
                    return {
                        ...currentState,
                        mic: false,
                    };
                });
                try {
                    let mediaStream = await navigator.mediaDevices.getUserMedia({ audio: false, video: true });
                    props.setListNotHavePermission(['mic']);
                    handleMediaStream(mediaStream, false, true);
                } catch (e) {
                    console.log('request camera loi');
                    props.setListNotHavePermission(['mic', 'camera']);
                    handleMediaStream(null, false, false);
                }
            }
        }
    };

    // const requestMediaWithDevices = async (mic, camera, callback) => {
    //     try {
    //         let mediaStream = await navigator.mediaDevices.getUserMedia({ audio: mic, video: camera });
    //         console.log('requestMediaWithDevices', mediaStream);
    //         if (mic && !camera) {
    //             // trường hợp có mic và không có camera
    //             props.setListNotHavePermission(['camera']);
    //             handleMediaStream(mediaStream, false);
    //         } else if (mic && camera) {
    //             // trường hợp có mic và camera
    //             props.setListNotHavePermission([]);
    //             handleMediaStream(mediaStream, true);
    //         } else if (!mic && camera) {
    //             // trường hợp không có mic và có camera
    //             props.setListNotHavePermission(['mic']);
    //             handleMediaStream(mediaStream, true);
    //         } else {
    //             //trường hợp không có mic và không có camera
    //             props.setListNotHavePermission(['camera', 'mic']);
    //             handleMediaStream(null, false);
    //         }
    //     } catch (e) {
    //         console.log('errrr', e, mic, callback);
    //         if (!camera && mic && callback) {
    //             callback(false, true, requestMediaWithDevices);
    //         }
    //         if (!mic && !camera) {
    //             //trường hợp không có stream
    //             props.setListNotHavePermission(['camera', 'mic']);
    //             handleMediaStream(null, false);
    //             return;
    //         }
    //         if (camera && mic && callback) {
    //             props.setListNotHavePermission(['camera']);
    //             //yêu cầu trường hợp có mic không có camera
    //             callback(true, false, requestMediaWithDevices);
    //         }
    //     }
    // };

    useEffect(() => {
        async function handleMedia() {
            loadingRef.current?.showLoading();
            // requestMediaWithDevices(true, true, requestMediaWithDevices);
            await newRequestMediaWithDevices();
        }

        handleMedia();
    }, []);

    const onMicClick = () => {
        try {
            if (props.stream) {
                props.stream.getAudioTracks()[0].enabled = !streamState?.mic;
                setStreamState((currentState) => {
                    defaultPreferencesRef.current = {
                        ...currentState,
                        audio: !currentState?.mic,
                    };
                    return {
                        ...currentState,
                        mic: !currentState?.mic,
                    };
                });
                // props.updateUser({ audio: !streamState.mic });
            } else {
                props.handleModalPermision('mic');
            }
        } catch (e) {
            console.log('not mic', e);
            props.handleModalPermision('mic');
        }
    };

    const onVideoClick = (videoEnabled) => {
        console.log('onVideoClick', props.stream, streamState);
        try {
            if (props.stream) {
                props.stream.getVideoTracks()[0].enabled = !streamState?.video;
                setStreamState((currentState) => {
                    defaultPreferencesRef.current = {
                        ...currentState,
                        video: !currentState?.video,
                    };
                    return {
                        ...currentState,
                        video: !currentState?.video,
                    };
                });
                // props.updateUser({ video: !streamState.video });
            } else {
                props.handleModalPermision('camera');
            }
        } catch (e) {
            console.log('not camera', e);
            props.handleModalPermision('camera');
        }
    };

    const handleJoinRoom = () => {
        let isLogin = checkLogin();
        if (isLogin) {
            props.setAccount(props.userProfile?.name ? props.userProfile?.name : 'User');
        }
    };

    const handleLogin = () => {
        store.dispatch(login(true));
    };

    const handleNotiPermission = () => {
        let textShow = notCamera && notMic ? 'mic và camera' : notCamera ? 'camera' : 'mic';
        props.handleModalPermision(textShow);
    };

    console.log('userProfileuserProfile', props.userProfile);
    let noti_permission = 'Bạn có muốn người khác nhìn thấy và nghe thấy bạn trong cuộc họp ?';
    let btn_noti_permission = 'Cho phép sử dụng mic và camera';

    let notMic = props.listNotHaveMedia.find((el) => el == 'mic');
    let notCamera = props.listNotHaveMedia.find((el) => el == 'camera');
    if (!notCamera && !streamState?.video) {
        noti_permission = 'Máy ảnh đang tắt.';
    } else if (notMic && !notCamera) {
        noti_permission = 'Bạn có muốn người khác nghe thấy bạn trong cuộc họp ?';
        btn_noti_permission = 'Cho phép sử dụng mic';
    } else if (!notMic && notCamera) {
        noti_permission = 'Bạn có muốn người khác nhìn thấy bạn trong cuộc họp ?';
        btn_noti_permission = 'Cho phép sử dụng camera';
    }
    return (
        <div className={cx('wrapper')}>
            <div className={cx('container')}>
                <h1 className={cx('title')}>Tham gia cuộc họp</h1>
                <div className={cx('controls')}>
                    <div className={cx('video-form')}>
                        <video
                            ref={videoRef}
                            style={{ objectFit: 'cover' }}
                            className={cx('video')}
                            // id={`participantVideo${curentIndex}`}
                            autoPlay
                            playsInline
                        ></video>
                        {!streamState?.video && (
                            <div className={cx('text_noti')}>
                                <h2>{noti_permission}</h2>
                                {notCamera && <button onClick={handleNotiPermission}>{btn_noti_permission}</button>}
                            </div>
                        )}

                        <div className={cx('list-button')}>
                            <div className={cx('button')}>
                                <ButtonIconText
                                    onClick={onMicClick}
                                    notPermission={notMic}
                                    icon={
                                        streamState?.mic ? (
                                            <IconMicList width={20} height={20} />
                                        ) : (
                                            <IconMutedVoice width={20} height={20} />
                                        )
                                    }
                                    text={streamState?.mic ? 'Turn Off' : 'Turn Audio'}
                                />
                            </div>
                            <div className={cx('button')}>
                                <ButtonIconText
                                    onClick={onVideoClick}
                                    notPermission={notCamera}
                                    icon={
                                        streamState?.video ? (
                                            <IconCameraList width={20} height={20} />
                                        ) : (
                                            <IconDisableCamera width={20} height={20} />
                                        )
                                    }
                                    text={streamState?.video ? 'Stop Video' : 'Start Video'}
                                />
                            </div>
                        </div>

                        {/* {!isHaveMedia && <span>Vui lòng cấp quyền để sử dụng mic và camera!</span>} */}
                    </div>
                    {props.userProfile ? (
                        <div className={cx('form-input')}>
                            {/* <input
                            className={cx('input')}
                            name="input"
                            value={value}
                            onChange={onTextChange}
                            placeholder="Nhập tên của bạn để tham gia cuộc họp."
                        /> */}
                            <h1>{`Hi! ${props.userProfile?.name}`}</h1>
                            <p>Bạn đã sẵn sàng tham gia ?</p>
                            <button
                                // disabled={value.length == 0}
                                onClick={handleJoinRoom}
                                className={cx('button-join-active')}
                            >
                                Tham gia
                            </button>
                        </div>
                    ) : (
                        <div className={cx('form-input')}>
                            <p>Vui lòng đăng nhập để tham gia cuộc họp.</p>
                            <button
                                // disabled={value.length == 0}
                                onClick={handleLogin}
                                className={cx('button-join-active')}
                            >
                                Đăng nhập
                            </button>
                        </div>
                    )}
                </div>
            </div>
            <ModalSupportPermision />
        </div>
    );
}
const mapStatetoProps = (state) => {
    return {
        stream: state.mainStream,
        userProfile: state.userProfile,
        listNotHaveMedia: state.listNotHaveMedia,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setAccount: (data) => dispatch(setAccount(data)),
        updateUser: (user) => dispatch(updateUser(user)),
        setUserStream: (stream) => dispatch(setUserStream(stream)),
        handleModalPermision: (status) => dispatch(handleModalPermision(status)),
        setListNotHavePermission: (list) => dispatch(setListNotHavePermission(list)),
    };
};

export default connect(mapStatetoProps, mapDispatchToProps)(RoomWaiting);

import React, { useImperativeHandle, useState } from 'react';
import LoadingOverlay from 'react-loading-overlay-ts';
import styles from './LoadingModal.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

const LoadingModal = React.forwardRef(({ children }, ref) => {
    useImperativeHandle(ref, () => ({
        showLoading,
        hideLoading,
    }));
    const [loading, setLoading] = useState(false);

    const showLoading = () => {
        setLoading(true);
    };

    const hideLoading = () => {
        setLoading(false);
    };
    return (
        <LoadingOverlay
            active={loading}
            spinner
            className={cx('wrapper')}
            // text='Loading your content...'
        >
            {children}
        </LoadingOverlay>
    );
});

export default LoadingModal;

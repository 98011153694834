import React from 'react';
import classNames from 'classnames/bind';
import { IconPeople } from '~/components/icons/LoadSvgIcon';
import ItemUser from '~/components/component/ItemUser';
import { ReactComponent as IconClose } from '~/assets/svg/close-icon.svg';
import styles from '../SideBarRoom.module.scss';
import { useSelector } from 'react-redux';
const cx = classNames.bind(styles);
export default function ListParticipants(props) {
    const participants = useSelector((state) => state.participants);
    return (
        <div className={cx('wrapper')}>
            <div className={cx('header')}>
                <div className={cx('title')}>
                    <IconPeople width={16} height={20} />
                    <p>Người tham gia</p>
                </div>
                <button onClick={props.hideSideBar} className={cx('btn-close')}>
                    <IconClose />
                </button>
            </div>

            <div className={cx('body')}>
                {Object.keys(participants).map((item, index) => (
                    <ItemUser user={participants[item]} key={index} />
                ))}
            </div>
            <div className={cx('footer')}>
                <button className={cx('btn-par')}>Mời thành viên</button>
                <button className={cx('btn-par')}>Tắt tiếng tất cả</button>
            </div>
        </div>
    );
}
